import { useState, useEffect, useRef, useMemo } from 'react'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { QuantityModal } from './components/quantity-modal';
import { AutoComplete, type Option } from "@/components/styled-autocomplete"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { Label } from "@/components/ui/label"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"


import { httpClient } from '@/httpClient'
import Footer from './components/footer'

import { CheckCircle, DeleteIcon } from 'lucide-react'
import getPharmacyID from '@/utils/pharmacy_code'
import { SearchAvailableSchema } from './models/search'
import { CompulsoryFields, InventoryCombinedData, Offer } from './models/inventoryData'
import { convertToCurrencyRounded, getDiscount, getItemCost } from '@/utils/currency_converter'
import { toast } from '@/components/ui/use-toast'
import { getMedicalItem, getNonMedicalItem } from './api'
import { useDebounce } from "@uidotdev/usehooks";
import dayjs from 'dayjs'
import StartShiftModal from '@/components/shifts/start-shift-modal'
import NoShiftFound from './components/no-shift-found'
import { Route as salesRoute } from '@/routes/sales/index'
import { useLiveQuery, usePGlite } from '@electric-sql/pglite-react'
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from '@/components/ui/context-menu'
import { useHotkeys } from 'react-hotkeys-hook';
import NoSaleSelected from './components/no-sale-selected'
import { DiscountModal } from './components/discount-modal';

const component = () => {
    const data = salesRoute.useLoaderData();
    const [search, setSearch] = useState('')
    const [results, setResults] = useState<Option[]>([])
    const [selected, setSelected] = useState<InventoryCombinedData[]>([])
    const [switchInsurance, setSwitchInsurance] = useState(false)
    const [emiratesID, setEmiratesID] = useState('')
    const [value, setValue] = useState<Option>()
    const [openShiftStart, setOpenShiftStart] = useState(!data.activeShiftFound) // Needs to be checked from backend @nanduri1998
    const [displaySalesComp, setDisplaySalesComp] = useState(data.activeShiftFound) // Needs to be checked from backend @nanduri1998
    const debouncedSearchTerm = useDebounce(search, 300);
    const autoCompleteRef = useRef<HTMLInputElement>(null);
    const checkoutButtonRef = useRef<HTMLButtonElement>(null);

    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

    useHotkeys('up', (event) => {
        event.preventDefault();
        setSelectedItemIndex((prevIndex) => {
            const newIndex = prevIndex > 0 ? prevIndex - 1 : 0;
            return newIndex;
        });
    });

    useHotkeys('down', (event) => {
        event.preventDefault();
        setSelectedItemIndex((prevIndex) => {
            const maxIndex = (selected_items?.rows?.filter(a => a.sale_id === selected_sale?.rows[0].sale_id).length || 0) - 1;
            const newIndex = prevIndex < maxIndex ? prevIndex + 1 : maxIndex;
            return newIndex;
        });
    });

    useHotkeys('s', (event) => {
        event.preventDefault();
        // Cycle between active_sales
        if (active_sales?.rows.length === 1) return;
        const currentIndex = active_sales?.rows.findIndex(a => a.id === selected_sale?.rows[0].sale_id);
        if (currentIndex === active_sales?.rows.length! - 1) {
            pglite.exec(`UPDATE selected_sale SET sale_id = ${active_sales?.rows[0].id}`);
        } else {
            pglite.exec(`UPDATE selected_sale SET sale_id = ${active_sales?.rows[currentIndex! + 1].id}`);
        }
    });

    useHotkeys('space', (event) => {
        event.preventDefault();
        autoCompleteRef.current?.focus();
    });

    useHotkeys('shift+enter', (event) => {
        event.preventDefault();
        if (checkoutButtonRef.current) {
            checkoutButtonRef.current.click();
        } else {
            console.log('Checkout button ref is null');
        }
    });

    useHotkeys('shift+s', (event) => {
        event.preventDefault();
        addNewMultiSale();
    });

    useHotkeys('shift+d', (event) => {
        event.preventDefault();
        if (active_sales?.rows.length === 1) return;
        try {
            pglite.transaction(async (tx) => {
                await tx.exec(`DELETE FROM sales_items WHERE sale_id = ${selected_sale?.rows[0].sale_id}`);
                await tx.exec(`DELETE FROM sales_items_batches WHERE sale_id = ${selected_sale?.rows[0].sale_id}`);
                await tx.exec(`DELETE FROM active_sales WHERE id = ${selected_sale?.rows[0].sale_id}`);
                await tx.exec(`UPDATE selected_sale SET sale_id = ${active_sales?.rows[0].id}`);
            })
        } catch (e) {
            console.error(e)
        }
    });

    useHotkeys('+,right', (event) => {
        event.preventDefault();
        if (selectedItemIndex >= 0 && selected_items?.rows) {
            const selectedItem = selected_items.rows.filter(a => a.sale_id === selected_sale?.rows[0].sale_id)[selectedItemIndex];
            if (selectedItem) {
                try {
                    pglite.transaction(async (tx) => {
                        const quantity = await tx.exec(`SELECT quantity_added FROM sales_items WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${selectedItem.item_id}'`);
                        const availableStock = parseFloat(selected_items_batches?.rows?.find((batch) => batch.item_id === selectedItem.item_id && batch.is_selected && batch.sale_id === selectedItem.sale_id)?.available_stock!);
                        if (quantity[0].rows[0].quantity_added === availableStock) {
                            toast({
                                title: "Error",
                                description: "Cannot add more than available stock",
                                duration: 5000,
                            });
                            return;
                        }
                        await tx.exec(`UPDATE sales_items SET quantity_added = quantity_added + 1 WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${selectedItem.item_id}'`);
                    })
                } catch (e) {
                    console.error(e)
                }
            }
        }
    });

    useHotkeys('-,left', (event) => {
        event.preventDefault();
        if (selectedItemIndex >= 0 && selected_items?.rows) {
            const selectedItem = selected_items.rows.filter(a => a.sale_id === selected_sale?.rows[0].sale_id)[selectedItemIndex];
            if (selectedItem) {
                try {
                    pglite.transaction(async (tx) => {
                        const quantity = await tx.exec(`SELECT quantity_added FROM sales_items WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${selectedItem.item_id}'`);
                        if (quantity[0].rows[0].quantity_added === 1) return;
                        await tx.exec(`UPDATE sales_items SET quantity_added = quantity_added - 1 WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${selectedItem.item_id}'`);
                    })
                } catch (e) {
                    console.error(e)
                }
            }
        }
    });

    useHotkeys('shift+backspace', (event) => {
        event.preventDefault();
        if (selectedItemIndex >= 0 && selected_items?.rows) {
            const selectedItem = selected_items.rows.filter(a => a.sale_id === selected_sale?.rows[0].sale_id)[selectedItemIndex];
            if (selectedItem) {
                try {
                    pglite.transaction(async (tx) => {
                        await tx.exec(`DELETE FROM sales_items WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${selectedItem.item_id}'`);
                        await tx.exec(`DELETE FROM sales_items_batches WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${selectedItem.item_id}'`);
                    })
                } catch (e) {
                    console.error(e)
                }
            }
        }
    });

    const pglite = usePGlite();

    const addNewMultiSale = async () => {
        await pglite.transaction(async (tx) => {
            const activeNumberOfSales = await tx.query<{ sale_count: number }>(`SELECT COUNT(*) as sale_count FROM active_sales`)
            if (activeNumberOfSales.rows[0].sale_count >= 3) {
                toast({
                    title: "Error",
                    description: "Cannot add more than 3 sales",
                    duration: 5000,
                });
                return;
            }
            await tx.exec(`INSERT INTO active_sales (created_at) VALUES (CURRENT_TIMESTAMP)`);
        })
    }

    const active_sales = useLiveQuery<
        {
            id: number,
            created_at: string
        }
    >(`SELECT * FROM active_sales`, []);
    const selected_sale = useLiveQuery<
        {
            id: number,
            sale_id: number
        }
    >(`SELECT * FROM selected_sale`, []);
    const selected_items = useLiveQuery<
        {
            id: number,
            sale_id: number,
            item_id: string,
            unit: number,
            unit_type: string,
            barcode: string,
            trade_name: string,
            quantity_added: string,
            item_discount: string,
            type: string
        }
    >(`SELECT * FROM sales_items ORDER BY id`, []);
    const selected_items_batches = useLiveQuery<
        {
            id: number,
            sale_id: number,
            item_id: string,
            inventory_id: string,
            batch_number: string,
            expiry_date: string,
            cost: string,
            price: string,
            available_stock: string,
            sold_quantity: string,
            price_history_id: string,
            vat: string,
            type: string,
            is_selected: boolean,
            max_discount_type: "PERCENTAGE" | "VALUE" | "ALWAYS" | "NONE",
            max_discount_amount: number,
            offers: string
        }
    >(`SELECT * FROM sales_items_batches ORDER BY id`, []);
    const { netTotal, totaldiscount, totalvat } = useMemo(() => {
        let netTotal = 0;
        let totaldiscount = 0;
        let totalvat = 0;
        if (!selected_items?.rows) {
            return {
                netTotal, totaldiscount, totalvat
            }
        }
        if (!selected_items_batches?.rows) {
            return {
                netTotal, totaldiscount, totalvat
            }
        }
        for (let item of selected_items.rows.filter(a => a.sale_id === selected_sale?.rows[0].sale_id)) {
            const price = parseFloat(selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.price!);
            const vat = parseFloat(selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.vat!);
            const available_stock = parseFloat(selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.available_stock!);
            const sold_quantity = parseFloat(selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.sold_quantity!);
            const inventory_id = selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.inventory_id;
            const discount_type = selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.max_discount_type;
            const item_cost = getItemCost({
                price: price,
                quantity: parseFloat(item.quantity_added!),
                vat: vat,
                discount: 0,
                discount_type: discount_type!
            })
            netTotal = netTotal + item_cost;
            const item_discount = getDiscount({
                price: price,
                quantity: parseFloat(item.quantity_added!),
                discount_amt: parseFloat(item.item_discount!),
                discount_type: discount_type!
            })
            totaldiscount = totaldiscount + item_discount;
            const itemVat = (item_cost - item_discount) * vat / 100;
            totalvat = totalvat + itemVat;
        }
        return {
            netTotal,
            totaldiscount,
            totalvat
        }
    }, [selected_items, selected_sale, selected_items_batches])
    useEffect(() => {
        try {
            const getMeds = async () => {
                const response = await httpClient.post(
                    `${import.meta.env.VITE_API_URL as string}/public/pharmacy/search/available`,
                    {
                        pharmacy_code: getPharmacyID(),
                        query: debouncedSearchTerm,
                        limit: 10,
                    }
                );
                const finalData = response.data.data as SearchAvailableSchema[]
                setResults(finalData.map((item) => {
                    const name = item.trade_name + (item.barcode ? " | " + item.barcode : "") + " | " + item.unit_type + ":::" + item.manufacturer + ":::" + item.owner
                    return {
                        value: item.id,
                        label: name,
                        type: item.type,
                    }
                }))
            }
            if (data.activeShiftFound) {
                getMeds()
            }
        } catch (error) {
            console.error('Error:', error);
            toast({
                title: "Error",
                description: "Failed to search",
                duration: 5000,
            });
        }
    }, [debouncedSearchTerm])

    useEffect(() => {
        if (data) {
            if (data.last_shift_system_ended) {
                // @Anan7Codes redirect here to Shift Summary page
                console.log(data.last_shift_id)
            }
            setOpenShiftStart(!data.activeShiftFound)
            setDisplaySalesComp(data.activeShiftFound)
        }
    }, [data])

    useEffect(() => {
        if (value) {
            const getMed = async () => {
                if (value.type === "MEDICAL") {
                    try {
                        const item = await getMedicalItem(value.value)
                        await pglite.transaction(async tx => {
                            try {
                                let item_discount = 0;
                                if (item[0].inventory_metadata.maximum_discount_type === "ALWAYS") {
                                    item_discount = item[0].inventory_metadata.maximum_discount_value;
                                }
                                const check_if_added = await tx.query<{ id: number }>(`SELECT id FROM sales_items WHERE sale_id = $1 AND item_id = $2 AND type = $3`, [selected_sale?.rows[0].sale_id, item[0].id, 'MEDICAL']);
                                if (check_if_added.rows.length > 0) {
                                    await tx.query(`UPDATE sales_items SET quantity_added = quantity_added + 1 WHERE sale_id = $1 AND item_id = $2 AND type = $3`, [selected_sale?.rows[0].sale_id, item[0].id, 'MEDICAL'])
                                } else {
                                    await tx.query(`INSERT INTO sales_items (sale_id, item_id, unit, unit_type, barcode, trade_name, quantity_added, item_discount, type) VALUES ($1, $2, $3, $4, $5, $6, $7, $8, $9)`, [selected_sale?.rows[0].sale_id, item[0].id, item[0].unit, item[0].unit_type, item[0].barcode, item[0].trade_name, 1, item_discount, "MEDICAL"]);
                                    for (let i = 0; i < item.length; i++) {
                                        await tx.query(`INSERT INTO sales_items_batches (sale_id, item_id, inventory_id, batch_number, expiry_date, cost, price, available_stock, sold_quantity, price_history_id, vat, type, is_selected, max_discount_type, max_discount_amount, offers) VALUES ($1, $2, $3, $4, $5, $6, $7, $8, $9, $10, $11, $12, $13, $14, $15, $16)`, [selected_sale?.rows[0].sale_id, item[0].id, item[i].inventory_id, item[i].batch_number, item[i].expiry_date, item[i].cost, item[i].price, item[i].available_stock, item[i].sold_quantity, item[i].medical_pharmacy_price_history_id, 0, "MEDICAL", i === 0, item[i].inventory_metadata.maximum_discount_type, item[i].inventory_metadata.maximum_discount_value, JSON.stringify(item[i].offers)]);
                                    }
                                }
                            } catch (e) {
                                console.error(e)
                            }
                        })
                    } catch (e) {
                        console.error(e)
                    }

                } else {
                    try {
                        const item = await getNonMedicalItem(value.value)
                        await pglite.transaction(async tx => {
                            try {
                                let item_discount = 0;
                                if (item[0].inventory_metadata.maximum_discount_type === "ALWAYS") {
                                    item_discount = item[0].inventory_metadata.maximum_discount_value;
                                }
                                const check_if_added = await tx.query<{ id: number }>(`SELECT id FROM sales_items WHERE sale_id = $1 AND item_id = $2 AND type = $3`, [selected_sale?.rows[0].sale_id, item[0].non_medical_master_id, 'MEDICAL']);
                                if (check_if_added.rows.length > 0) {
                                    await tx.query(`UPDATE sales_items SET quantity_added = quantity_added + 1 WHERE sale_id = $1 AND item_id = $2 AND type = $3`, [selected_sale?.rows[0].sale_id, item[0].non_medical_master_id, 'MEDICAL'])
                                } else {
                                    await tx.query(`INSERT INTO sales_items (sale_id, item_id, unit, unit_type, barcode, trade_name, quantity_added, item_discount, type) VALUES ($1, $2, $3, $4, $5, $6, $7, $8, $9)`, [selected_sale?.rows[0].sale_id, item[0].non_medical_master_id, item[0].unit, item[0].unit_type, item[0].barcode, item[0].trade_name, 1, item_discount, "NON_MEDICAL"]);
                                    for (let i = 0; i < item.length; i++) {
                                        await tx.query(`INSERT INTO sales_items_batches (sale_id, item_id, inventory_id, batch_number, expiry_date, cost, price, available_stock, sold_quantity, price_history_id, vat, type, is_selected, max_discount_type, max_discount_amount, offers) VALUES ($1, $2, $3, $4, $5, $6, $7, $8, $9, $10, $11, $12, $13, $14, $15, $16)`, [selected_sale?.rows[0].sale_id, item[0].non_medical_master_id, item[i].non_medical_inventory_id, item[i].batch_number, item[i].expiry_date, item[i].cost, item[i].price, item[i].available_stock, item[i].sold_quantity, item[i].non_medical_pharmacy_price_history_id, item[i].vat, "NON_MEDICAL", i === 0, item[i].inventory_metadata.maximum_discount_type, item[i].inventory_metadata.maximum_discount_value, JSON.stringify(item[i].offers)]);
                                    }
                                }
                            } catch (e) {
                                console.error(e)
                            }
                        })
                    } catch (e) {
                        console.error(e)
                    }
                }
                setSearch('')
                setResults([])
            }
            getMed()
        }
    }, [value])

    return (
        <>
            <StartShiftModal
                value={openShiftStart}
                setValue={setOpenShiftStart}
                setDisplaySalesComp={setDisplaySalesComp}
                showTriggerButton={false}
                page='sales'
            />
            {
                !displaySalesComp
                    ?
                    <NoShiftFound
                        value={openShiftStart}
                        setValue={setOpenShiftStart}
                        setDisplaySalesComp={setDisplaySalesComp}
                    />
                    :
                    <div
                        className='h-full flex'
                    >
                        <div className='flex flex-col w-full'>
                            <div className={``}>
                                <div className='flex items-center justify-between border-b-[0.5px] pb-4 mb-4'>
                                    <div className='flex items-center justify-center space-x-6'>
                                        {active_sales?.rows.map((s, i) => (
                                            <div
                                                key={i}
                                                onClick={async () => {
                                                    await pglite.exec(`UPDATE selected_sale SET sale_id = ${s.id}`)
                                                }}
                                            >
                                                <ContextMenu>
                                                    <ContextMenuTrigger>
                                                        <p className={`text-sm font-semibold cursor-pointer ${selected_sale?.rows[0].sale_id === s.id ? "bg-gray-100" : ""
                                                            } px-3 py-2 rounded-lg`}>Sale {i + 1}</p>
                                                    </ContextMenuTrigger>
                                                    <ContextMenuContent>
                                                        <ContextMenuItem onClick={async () => {
                                                            try {
                                                                await pglite.exec(`UPDATE selected_sale SET sale_id = ${s.id}`)
                                                            } catch (e) {
                                                                console.error(e)
                                                            }
                                                        }}>Go to Sale</ContextMenuItem>
                                                        <ContextMenuItem onClick={async () => {
                                                            try {
                                                                await pglite.transaction(async (tx) => {
                                                                    await tx.exec(`DELETE FROM sales_items WHERE sale_id = ${s.id}`);
                                                                    await tx.exec(`DELETE FROM sales_items_batches WHERE sale_id = ${s.id}`);
                                                                    await tx.exec(`DELETE FROM active_sales WHERE id = ${s.id}`);
                                                                })
                                                                await pglite.exec(`UPDATE selected_sale SET sale_id = ${active_sales?.rows[0].id}`);
                                                            } catch (e) {
                                                                console.error(e)
                                                            }
                                                        }} disabled={
                                                            active_sales?.rows.length === 1
                                                        }>Discard Sale</ContextMenuItem>
                                                    </ContextMenuContent>
                                                </ContextMenu>
                                            </div>
                                        ))}
                                        <Button
                                            variant="outline"
                                            className='text-sm'
                                            onClick={addNewMultiSale}
                                        >
                                            <p>+ Add Sale</p>
                                        </Button>
                                    </div>
                                    {/* <div className="flex items-center justify-between border rounded-md space-x-4 px-4 py-3 min-w-[200px]">
                                        <p className='text-sm font-semibold'>Insurance Sale</p>
                                        <Switch 
                                            className='w-10 h-4'
                                            checked={switchInsurance}
                                            onCheckedChange={() => {setSwitchInsurance(!switchInsurance)}}
                                        />
                                    </div> */}
                                </div>

                                {active_sales?.rows.map(a => a.id).includes(selected_sale?.rows[0].sale_id!) ? <div className='flex items-center justify-center space-x-12 mb-8'>
                                    <p className='text-3xl font-semibold'>Product list</p>
                                    <div className='flex-1 mx-4 relative'>
                                        <div className='border rounded-md px-2 flex items-center'>
                                            <AutoComplete
                                                options={results}
                                                emptyMessage="No results."
                                                placeholder="Search for a product"
                                                onValueChange={setValue}
                                                value={value}
                                                input={search}
                                                onInputChanged={setSearch}
                                                shouldFilter={false}
                                                ref={autoCompleteRef}
                                            />
                                            <Button
                                                className='px-2 py-0 h-8'
                                                variant={'outline'}
                                                size={'sm'}
                                                onClick={() => {
                                                    setSearch('');
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </div>
                                    </div>

                                    <Button
                                        onClick={() => {
                                            setSelected([])
                                            try {
                                                pglite.transaction(async (tx) => {
                                                    await tx.exec(`DELETE FROM sales_items WHERE sale_id = ${selected_sale?.rows[0].sale_id}`);
                                                    await tx.exec(`DELETE FROM sales_items_batches WHERE sale_id = ${selected_sale?.rows[0].sale_id}`);
                                                })
                                            } catch (e) {
                                                console.error(e)
                                            }
                                        }}
                                    >
                                        Remove All
                                    </Button>
                                </div> : <NoSaleSelected />}
                            </div>
                            {active_sales?.rows.map(a => a.id).includes(selected_sale?.rows[0].sale_id!) && <div className='flex-1 overflow-auto pb-4'>
                                <div className={`h-full relative flex flex-col`}>
                                    <Table className='mb-24'>
                                        <TableHeader>
                                            <TableRow>
                                                <TableHead>Product Name</TableHead>
                                                <TableHead>Batch No. | Barcode Number | Expiry Date</TableHead>
                                                <TableHead>Available Stock</TableHead>
                                                <TableHead>Unit Type</TableHead>
                                                <TableHead>Quantity</TableHead>
                                                <TableHead>Discount</TableHead>
                                                <TableHead>Total</TableHead>
                                                <TableHead></TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {
                                                selected_items?.rows?.map && selected_items.rows.filter(a => a.sale_id === selected_sale?.rows[0].sale_id).map((item, index: number) => {
                                                    const price = parseFloat(selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.price!);
                                                    const vat = parseFloat(selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.vat!);
                                                    const available_stock = parseFloat(selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.available_stock!);
                                                    const sold_quantity = parseFloat(selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.sold_quantity!);
                                                    const inventory_id = selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.inventory_id;
                                                    const discount_type = selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.max_discount_type;
                                                    const offers = JSON.parse(selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.offers!) as Offer[];
                                                    return (
                                                        <TableRow
                                                            key={index}
                                                            className={index === selectedItemIndex ? 'bg-gray-100' : ''}>
                                                            <TableCell>{item.trade_name}</TableCell>
                                                            <TableCell>
                                                                <Select
                                                                    value={inventory_id}
                                                                    onValueChange={(value) => {
                                                                        try {
                                                                            pglite.transaction(async (tx) => {
                                                                                await tx.exec(`UPDATE sales_items_batches SET is_selected = FALSE WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${item.item_id}'`);
                                                                                await tx.exec(`UPDATE sales_items_batches SET is_selected = TRUE WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${item.item_id}' AND inventory_id = '${value}'`);
                                                                                await tx.exec(`UPDATE sales_items SET quantity_added = 1 WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${item.item_id}'`);
                                                                                const discount_type_target = selected_items_batches?.rows?.find((batch) => batch.inventory_id === value && batch.item_id === item.item_id && batch.sale_id === item.sale_id)?.max_discount_type;
                                                                                if (discount_type_target === "ALWAYS") {
                                                                                    await tx.exec(`UPDATE sales_items SET item_discount = ${selected_items_batches?.rows?.find((batch) => batch.inventory_id === value && batch.item_id === item.item_id && batch.sale_id === item.sale_id)?.max_discount_amount} WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${item.item_id}'`);
                                                                                } else {
                                                                                    await tx.exec(`UPDATE sales_items SET item_discount = 0 WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${item.item_id}'`);
                                                                                }                        
                                                                            })
                                                                        } catch (e) {
                                                                            console.error(e)
                                                                        }
                                                                    }}
                                                                >
                                                                    <SelectTrigger className="w-[280px]">
                                                                        <SelectValue placeholder="Select a batch" />
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        <SelectGroup>
                                                                            {
                                                                                selected_items_batches?.rows.filter(a => {
                                                                                    return a.item_id === item.item_id && a.sale_id === selected_sale?.rows[0].sale_id;
                                                                                }).map((batch, index) => (
                                                                                    <SelectItem key={index} value={batch.inventory_id} disabled={parseFloat(batch.available_stock) <= 0}>
                                                                                        {batch.batch_number} {item.barcode ? "| " + item.barcode : ""} | {dayjs(batch.expiry_date).isValid() ? dayjs(batch.expiry_date).format('DD/MM/YYYY') : "N/A"}
                                                                                    </SelectItem>
                                                                                ))
                                                                            }
                                                                        </SelectGroup>
                                                                    </SelectContent>
                                                                </Select>

                                                            </TableCell>
                                                            <TableCell>{available_stock}</TableCell>
                                                            <TableCell>{item.unit_type}</TableCell>
                                                            <TableCell className="flex items-center gap-2">
                                                                <QuantityModal
                                                                    item={item}
                                                                    available_stock={parseFloat(selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.available_stock!)}
                                                                    selected_sale={selected_sale?.rows[0].sale_id!}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <DiscountModal
                                                                    item_discount={item.item_discount}
                                                                    trade_name={item.trade_name}
                                                                    allowed_to_open={discount_type !== "NONE" || offers?.length! > 0}
                                                                    discount_type={discount_type!}
                                                                    max_discount_value={selected_items_batches?.rows?.find((batch) => batch.item_id === item.item_id && batch.is_selected && batch.sale_id === item.sale_id)?.max_discount_amount!}
                                                                    price={price}
                                                                    sale_id={selected_sale?.rows[0].sale_id!}
                                                                    item_id={item.item_id}
                                                                    quantity={parseFloat(item.quantity_added)}
                                                                    offers={offers || []}
                                                                />
                                                            </TableCell>
                                                            {/* <TableCell>{convertToCurrencyRounded(parseFloat(item.item_discount!))}</TableCell> */}
                                                            <TableCell>
                                                                {
                                                                    convertToCurrencyRounded(getItemCost({
                                                                        price: price!,
                                                                        quantity: parseFloat(item.quantity_added!),
                                                                        vat: vat!,
                                                                        discount: parseFloat(item.item_discount!),
                                                                        discount_type: discount_type!
                                                                    }))
                                                                }
                                                            </TableCell>
                                                            {/* <TableCell>{convertToCurrencyRounded(((price! * parseFloat(item.quantity_added!)) + ((price! * parseFloat(item.quantity_added!)) * vat! / 100)) - parseFloat(item.item_discount))}</TableCell> */}
                                                            <TableCell><DeleteIcon onClick={async () => {
                                                                try {
                                                                    await pglite.transaction(async (tx) => {
                                                                        await tx.exec(`DELETE FROM sales_items WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${item.item_id}'`);
                                                                        await tx.exec(`DELETE FROM sales_items_batches WHERE sale_id = ${selected_sale?.rows[0].sale_id} AND item_id = '${item.item_id}'`);
                                                                    })
                                                                } catch (e) {
                                                                    console.error(e)
                                                                }
                                                            }} style={{
                                                                cursor: 'pointer'
                                                            }} /></TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                                )}
                                        </TableBody>
                                    </Table>
                                    <div className='w-full absolute bottom-0'>
                                        <Footer
                                            internalSalesId={selected_sale?.rows[0].sale_id!}
                                            total={
                                                netTotal
                                            }
                                            discount={
                                                totaldiscount
                                            }
                                            vat={
                                                totalvat
                                            }
                                            checkoutButtonRef={checkoutButtonRef}
                                        />
                                    </div>
                                </div>
                            </div>}
                        </div>
                        {
                            switchInsurance ? (
                                <div className='bg-[#F9FAFB] flex-1 px-4 mx-2 min-w-[400px] relative'>
                                    <div className="grid w-full max-w-sm items-center gap-1.5 mt-4">
                                        <Label
                                            htmlFor="emirates"
                                            aria-required="true"
                                        >Emirates ID <span className='text-red-500'>*</span></Label>
                                        <Input
                                            type="text"
                                            id="emirates"
                                            placeholder="***-****-*******-*"
                                            value={emiratesID}
                                            onChange={(event) => {
                                                let newValue = event.target.value.replace(/\D/g, ''); // Remove non-digits

                                                // Insert dashes at the correct positions
                                                if (newValue.length > 3) {
                                                    newValue = newValue.slice(0, 3) + '-' + newValue.slice(3);
                                                }
                                                if (newValue.length > 8) {
                                                    newValue = newValue.slice(0, 8) + '-' + newValue.slice(8);
                                                }
                                                if (newValue.length > 16) {
                                                    newValue = newValue.slice(0, 16) + '-' + newValue.slice(16);
                                                }
                                                setEmiratesID(newValue);
                                            }}
                                            maxLength={18}
                                            required
                                        />
                                    </div>

                                    <div className="grid w-full max-w-sm items-center gap-1.5 mt-4">
                                        <Label
                                            htmlFor="erx"
                                            aria-required="true"
                                        >ERX <span className='text-red-500'>*</span></Label>
                                        <Input
                                            type="text"
                                            id="erx"
                                            placeholder="Need to check"
                                            // value={erx}
                                            // onChange={(event) => {
                                            //     let newValue = event.target.value.replace(/\D/g, ''); // Remove non-digits

                                            //     // Insert dashes at the correct positions
                                            //     if (newValue.length > 3) {
                                            //     newValue = newValue.slice(0, 3) + '-' + newValue.slice(3);
                                            //     }
                                            //     if (newValue.length > 8) {
                                            //     newValue = newValue.slice(0, 8) + '-' + newValue.slice(8);
                                            //     }
                                            //     if (newValue.length > 16) {
                                            //     newValue = newValue.slice(0, 16) + '-' + newValue.slice(16);
                                            //     }
                                            //     setERX(newValue);
                                            // }}
                                            required
                                        />
                                    </div>

                                    <hr
                                        className='my-4'
                                    />

                                    <Button
                                        className='w-full'
                                    >
                                        <CheckCircle
                                            className='mr-2 h-4 w-4'
                                        /> Submit & Retrieve items
                                    </Button>

                                    <Button
                                        className='w-full mt-4 text-gray-400'
                                        variant={'outline'}
                                    >
                                        <CheckCircle
                                            className='mr-2 h-4 w-4 text-gray-400'
                                        /> Print Prescription
                                    </Button>

                                    <div
                                        className='absolute bottom-2 bg-[#EF4444] p-4 rounded-lg'
                                    >
                                        <p className='text-[#FAFAFA] font-semibold'>ERX not found!</p>
                                        <p className='text-[#FAFAFA] text-sm'>Please check the prescription and try again.</p>
                                        <Button
                                            variant={'outline'}
                                            className='text-white bg-[#EF4444] mt-4'
                                        >Try Again</Button>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
            }
        </>
    )
}

export default component