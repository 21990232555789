import { createFileRoute, notFound } from "@tanstack/react-router";
import Component from "@/screens/inventory/item/component";
import { SearchParams } from "@/screens/inventory/available-inventory/model";
import { getMedicalItemDetails, getMedicalItemInventory, getMedicalItemPricingHistoryMaster, getMedicalItemPricingHistoryPharmacy, getNonMedicalItemDetails, getNonMedicalItemInventory } from "@/screens/inventory/item/api";
import { CombinedItemOneData, CombinedItemSchema, MedicalPricingHistoryMaster, MedicalPricingHistoryPharmacy } from "@/screens/inventory/item/model";
import { Loading } from "@/layouts/loading";

type Props = {
    inventoryData: CombinedItemSchema,
    itemData: CombinedItemOneData,
    pricingHistoryMaster: MedicalPricingHistoryMaster[],
    pricingHistoryPharmacy: MedicalPricingHistoryPharmacy[]
}

export const Route = createFileRoute("/inventory/item/$type/$itemId")({
    loader: async ({ params }): Promise<Props> => {
        if (params.type !== "MEDICAL" && params.type !== "NON_MEDICAL") {
            throw notFound();
        }
        if (!params.itemId) {
            throw notFound();
        }
        if (params.type === "MEDICAL") {
            const inventory_data = await getMedicalItemInventory(params.itemId);
            const item_data = await getMedicalItemDetails(params.itemId);
            const pricing_history_master = await getMedicalItemPricingHistoryMaster(params.itemId);
            const pricing_history_pharmacy = await getMedicalItemPricingHistoryPharmacy(params.itemId);
            return {
                inventoryData: {
                    type: 'MEDICAL',
                    inventory_data: inventory_data
                },
                itemData: {
                    type: 'MEDICAL',
                    item_data: item_data
                },
                pricingHistoryMaster: pricing_history_master,
                pricingHistoryPharmacy: pricing_history_pharmacy
            }
        } else {
            const inventory_data = await getNonMedicalItemInventory(params.itemId);
            const item_data = await getNonMedicalItemDetails(params.itemId);
            return {
                inventoryData: {
                    type: 'NON_MEDICAL',
                    inventory_data: inventory_data
                },
                itemData: {
                    type: 'NON_MEDICAL',
                    item_data: item_data
                },
                pricingHistoryMaster: [],
                pricingHistoryPharmacy: []
            }
        }
    },
    validateSearch: SearchParams,
    component: Component,
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1
});