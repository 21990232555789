import {
  Outlet,
  ScrollRestoration,
  useRouterState,
} from "@tanstack/react-router";
import Header from "./header";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getOidc } from "@/oidc";
import { redirect } from "@tanstack/react-router";
import GlobalCommandMenu from "@/components/command-modal/globalCommandModal";
import { usePGlite } from "@electric-sql/pglite-react";
import { Input } from "@/components/ui/input";


//only avialable when VITE_ENV is "DEV" and ctrl + shift + Q is pressed
const Repl = () => {
  const pglite = usePGlite();
  const [q, setQ] = useState<string>("")
  const [history, setHistory] = useState<string[]>([])
  const [histIdx, setHistIdx] = useState<number>()
  return <form onSubmit={(e) => {
    e.preventDefault()
    console.log(q)
    //@ts-ignore
    pglite.exec(q).then((r) => console.log(r[0].rows)).catch((e) => console.error(e))
    setHistory([q, ...history])
    setQ("")
  }}>
    <Input value={q}
      onKeyDown={(e) => {
        if (e.key === "ArrowUp") {
          if (histIdx === undefined) {
            setHistIdx(0)
            setQ(history[0])
          }
          else if (histIdx < history.length - 1) {
            setHistIdx(histIdx + 1)
            setQ(history[histIdx + 1])
          }
        }
        if (e.key === "ArrowDown") {

          if (histIdx && histIdx > 0) {
            setHistIdx(histIdx - 1)
            setQ(history[histIdx - 1])
          }
          else if (histIdx === 0) {
            setHistIdx(undefined)
            setQ("")
          }
        }
      }}

      onChange={(e) => {
        setQ(e.target.value)
      }}></Input>
  </form>
}

const PrintContext = createContext<PrintContext | null>(null)

export function usePrint() {
  const ctx = useContext(PrintContext)
  if (!ctx) {
    throw new Error("usePrint must be used within a PrintProvider")
  }
  return ctx
}

type PrintContext = {
  printMode: boolean,
  setPrintMode: (v: boolean) => void
}

export default function Component() {
  const router = useRouterState();
  const pglite = usePGlite();
  const [showRepl, setShowRepl] = useState(false)
  const [printMode, setPrintMode] = useState(false)
  const printContextValue = useMemo(
    () => ({
      printMode,
      setPrintMode
    })
    , [printMode, setPrintMode])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.key === "Q") {
        setShowRepl((v) => !v)
      }
    }
    window.addEventListener("keydown", handleKeyDown)

    const initialLoad = async () => {
      try {
        const oidc = await getOidc();
        if (!oidc.isUserLoggedIn) {
          await oidc.login({
            doesCurrentHrefRequiresAuth: true,
          });
        } else {
          const pharmacy_id_list = oidc.getTokens().decodedIdToken.pharmacy_id;
          localStorage.setItem(
            "pharmacy_id_list",
            JSON.stringify(pharmacy_id_list)
          );
          localStorage.setItem("token", oidc.getTokens().accessToken);
          if (pharmacy_id_list.length === 1) {
            localStorage.setItem("pharmacy_id", pharmacy_id_list[0]);
            if (router.location.pathname === "/") {
              throw redirect({ to: "/overview" });
            }
          } else {
            throw redirect({ to: "/" });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    initialLoad();
    async function initialCreation() {
      console.log("Creating tables");
      await pglite.transaction(async (tx) => {
        await tx.exec(
          `CREATE TABLE IF NOT EXISTS active_sales (id SERIAL PRIMARY KEY, created_at TIMESTAMP)`
        );
        await tx.exec(
          `INSERT INTO active_sales (id, created_at) VALUES (1, CURRENT_TIMESTAMP) ON CONFLICT DO NOTHING`
        );
        await tx.exec(
          `CREATE TABLE IF NOT EXISTS selected_sale (sale_id INTEGER)`
        );
        await tx.exec(
          `INSERT INTO selected_sale (sale_id) VALUES (1) ON CONFLICT DO NOTHING`
        );
        await tx.exec(
          `CREATE TABLE IF NOT EXISTS sales_items (id SERIAL PRIMARY KEY, sale_id INTEGER, item_id TEXT, unit INTEGER, unit_type TEXT, barcode TEXT, trade_name TEXT, quantity_added FLOAT, item_discount FLOAT, type TEXT)`
        );
        await tx.exec(
          `CREATE TABLE IF NOT EXISTS sales_items_batches (id SERIAL PRIMARY KEY, sale_id INTEGER, item_id TEXT, inventory_id TEXT, batch_number TEXT, expiry_date TEXT, cost DECIMAL, price DECIMAL, available_stock FLOAT, sold_quantity FLOAT, price_history_id TEXT, vat FLOAT, type TEXT, is_selected BOOLEAN DEFAULT FALSE)`
        );
        await tx.exec(
          `ALTER TABLE sales_items_batches ADD COLUMN IF NOT EXISTS max_discount_type TEXT`
        );
        await tx.exec(
          `ALTER TABLE sales_items_batches ADD COLUMN IF NOT EXISTS max_discount_amount FLOAT`
        );
        await tx.exec(
          `ALTER TABLE sales_items_batches ADD COLUMN IF NOT EXISTS offers text`
        )
        await tx.exec(
          `CREATE TABLE IF NOT EXISTS create_pi_instance (id SERIAL PRIMARY KEY, complete BOOLEAN, updated_at TIMESTAMP, type TEXT)`
        )
        //match PurchaseInvoiceSchema
        await tx.exec(
          `CREATE TABLE IF NOT EXISTS create_pi_info (
          id SERIAL PRIMARY KEY,
          instance_id INTEGER,
          invoice_date DATE,
          invoice_number TEXT,
          delivery_date DATE,
          payment_mode TEXT,
          payment_type TEXT,
          supplier_id TEXT,
          instance_type TEXT
          )`
        )
        await tx.exec(
          `CREATE TABLE IF NOT EXISTS add_stock_instance (id SERIAL PRIMARY KEY, complete BOOLEAN, updated_at TIMESTAMP)`
        )
        await tx.exec(
          `DELETE FROM create_pi_instance WHERE complete = TRUE`
        )
        //match addItemForm
        await tx.exec(
          `CREATE TABLE IF NOT EXISTS add_stock_item (
          id SERIAL PRIMARY KEY, 
          instance_id INTEGER,
          item_id TEXT,
          product_name TEXT,
          type TEXT, 
          batch_no TEXT,
          cost DECIMAL,
          price DECIMAL,
          expiry_date DATE,
          production_date DATE,
          custom_barcode TEXT,
          discount_type TEXT,
          vat DECIMAL,
          discount_value DECIMAL,
          quantity INTEGER,
          bonus INTEGER,
          instance_type TEXT
          )`
        )
        await tx.exec(
          `DELETE FROM add_stock_instance WHERE complete = TRUE`
        )

        await tx.exec(
          `CREATE TABLE IF NOT EXISTS draft_pi_instance (id SERIAL PRIMARY KEY, updated_at TIMESTAMP, complete BOOLEAN, po_id TEXT)`
        )
      });
    }
    initialCreation();
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, []);
  return (
    <>
      <PrintContext.Provider value={printContextValue}>
        {/* <Header /> */}
        <main className={`${printMode ? "" : "max-w-screen h-screen max-h-screen overflow-hidden"}`}>
          <div className={`flex flex-col w-full h-full`}>
            {
              showRepl && import.meta.env.VITE_ENV === "DEV" && <Repl />
            }
            {
              printMode || <Header />
            }
            <div className={`px-4 mb-4 h-full relative ${printMode ? "" : "overflow-hidden"}`}>
              <GlobalCommandMenu />
              <Outlet />
            </div>
          </div>
        </main>
        <ScrollRestoration />
      </PrintContext.Provider>
    </>
  );
}
