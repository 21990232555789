import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { useNavigate } from '@tanstack/react-router';
import TableList from "./components/table-list";
import Add, { AddItemForm } from "./components/add";
import { Button } from '@/components/ui/button'
import { ArrowBigRightDashIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { AddedItemsSchema } from "./schema";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import { submitInventory } from "../item/api";
import { toast } from "@/components/ui/use-toast";
import { Loading } from "@/layouts/loading";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { usePGlite } from "@electric-sql/pglite-react";
import dayjs from "dayjs";
import getPharmacyID from "@/utils/pharmacy_code";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { addAddStockItem, AddStockItemRow, deleteAddStockItem, updateAddStockItem } from "@/utils/db/addStock";
import { completeAddStockInstance } from "@/utils/db/purchaseInvoice";

export const formObjToAddedItems = (formObj: AddItemForm, dbItemId: number): AddedItemsSchema => {
    return {
        db_item_id: dbItemId,
        trade_name: formObj.trade_name,
        type: formObj.type,
        medical_master_id: formObj.type === "MEDICAL" ? formObj.item_id : undefined,
        non_medical_master_id: formObj.type === "NON_MEDICAL" ? formObj.item_id : undefined,
        batch_number: formObj.batchNo,
        expiry_date: new Date(formObj.expiryDate!) || dayjs().add(1, "year").toDate(),
        production_date: new Date(formObj.productionDate!),
        cost: formObj.cost.value,
        price: formObj.price.value,
        quantity: {
            original: formObj.quantity.value,
            bonus: formObj.bonus.value,
        },
        pharmacy_code: getPharmacyID(),
        metadata: {
            custom_barcode: formObj.customBarcode,
            discount_type: formObj.discountType,
            discount_value: formObj.discountValue.value,
        },
        vat: formObj.vat.toString()
    }
}

const Component1 = ({ head, foot }: any) => {
    return (
        <span className='flex items-center mx-2'>
            <p className='text-sm font-semibold mr-8'>{head}</p>
            <p className='text-gray-400 text-sm'>{foot}</p>
        </span>
    )
}

const Component = () => {
    const navigate = useNavigate()
    const [items, setItems] = useState<AddedItemsSchema[]>([])
    const [loading, setLoading] = useState(false)
    const [editItem, setEditItem] = useState<AddedItemsSchema>()
    const [lastItems, setLastItems] = useState<AddedItemsSchema[] | undefined>(undefined)
    const [instanceId, setInstanceId] = useState("")
    const pglite = usePGlite()

    const getLastAttempt = async () => {
        const rows = await pglite.query
            <
                AddStockItemRow & {
                    updated_at: string
                    instance_id: number
                }
            >
            (`SELECT item.*,instance.updated_at as updated_at,instance.id as instance_id  FROM add_stock_item item JOIN add_stock_instance instance ON item.instance_id = instance.id WHERE instance.complete = FALSE AND item.instance_type='ADD_STOCK' ORDER BY instance.updated_at DESC`)
        if (rows.rows.length === 0) {
            return undefined
        }
        return rows.rows
    }

    useEffect(() => {
        getLastAttempt().then((rows) => {
            if (rows) {
                setInstanceId(rows[0].instance_id.toString())
                console.log(rows)
                const items: AddedItemsSchema[] = rows.map((row) => {
                    const { instance_id, updated_at, ...rest } = row
                    return {
                        batch_number: rest.batch_no,
                        db_item_id: rest.id,
                        cost: Number(rest.cost),
                        medical_master_id: rest.type === "MEDICAL" ? rest.item_id : undefined,
                        non_medical_master_id: rest.type === "NON_MEDICAL" ? rest.item_id : undefined,
                        price: rest.type === "NON_MEDICAL" ? Number(rest.price) : undefined,
                        production_date: rest.production_date,
                        trade_name: rest.product_name,
                        type: rest.type as "MEDICAL" | "NON_MEDICAL",
                        expiry_date: rest.expiry_date,
                        quantity: {
                            original: Number(rest.quantity),
                            bonus: Number(rest.bonus)
                        },
                        pharmacy_code: getPharmacyID(),
                        metadata: {
                            custom_barcode: rest.custom_barcode,
                            discount_type: rest.discount_type as "NONE" | "PERCENTAGE" | "VALUE" | "ALWAYS" | undefined,
                            discount_value: Number(rest.discount_value),
                        },
                        vat: rest.vat.toString()

                    }
                })
                setLastItems(items)
            }
        })
    }, [])

    return (
        <Dialog open={lastItems != undefined}>
            <DialogContent>
                <div className="flex flex-col">
                    <p>
                        Previous attempt found
                    </p>
                    <div className="flex w-full justify-end gap-2">
                        <Button onClick={() => {
                            if (lastItems) {
                                setItems(lastItems)
                                setLastItems(undefined)
                            }
                        }}>
                            Load
                        </Button>
                        <Button onClick={() => {
                            //if dialog is open then there is instance_id in the purchaseinvoice data
                            completeAddStockInstance(pglite, Number(instanceId), () => {
                                setLastItems(undefined)
                                setInstanceId("")
                                setItems([])
                                console.log("Ignored and deleted")
                            }, (e) => {
                                console.log(e)
                                // alert("Error occurred")
                                // window.location.reload()
                            })
                        }}>
                            Ignore
                        </Button>
                    </div>
                </div>
            </DialogContent>
            <div className="overflow-hidden h-full">
                <div className='flex items-center justify-between'>
                    <Breadcrumb>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <BreadcrumbLink
                                    onClick={() => {
                                        navigate({
                                            to: "/inventory",
                                        });
                                    }}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Inventory Overview
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbPage>Add Stock</BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </div>
                <hr className='mt-4' />
                <ResizablePanelGroup direction={"horizontal"} className="h-full flex">
                    <ResizablePanel className="flex-1 mr-2">
                        <div className="flex flex-col justify-between h-full">
                            {!loading && <TableList
                                items={items}
                                setEditItem={setEditItem}
                                deleteItem={(item) => {
                                    deleteAddStockItem(pglite, item.db_item_id, () => {
                                        setItems(items.filter((i) => i.db_item_id !== item.db_item_id))
                                    }, (e) => {
                                        console.log(e)
                                        toast({
                                            title: "Error",
                                            description: "Failed to delete item. Please try again later. If the problem persists, contact support.",
                                            duration: 5000,
                                        })
                                    })
                                }}
                            />}
                            {loading && <Loading />}
                            <div className='flex justify-between items-center bg-[#F6F6F6] sticky bottom-10 rounded-2xl h-[50px]'>
                                <div className='flex'>
                                    <Component1
                                        head='Item'
                                        foot={items.length}
                                    />
                                    <Component1
                                        head='Total Qty.'
                                        foot={items.reduce((acc, item) => acc + item.quantity.original + item.quantity.bonus, 0)}
                                    />
                                </div>
                                <div className='bg-[#F6F6F6] flex space-x-2'>
                                    <Component1
                                        head='Total Amount'
                                        foot={convertToCurrencyRounded(items.reduce((acc, item) => acc + item.quantity.original * item.cost, 0))}
                                    />
                                    <Button
                                        className='bg-blue-700 px-6 rounded-none rounded-r-2xl'
                                        size={'lg'}
                                        disabled={items.length === 0 || loading}
                                        onClick={async () => {
                                            try {
                                                if (confirm('Are you sure you want to add this inventory?')) {
                                                    setLoading(true)
                                                    submitInventory(items).then(() => {
                                                        completeAddStockInstance(pglite, Number(instanceId),
                                                            () => {
                                                                setInstanceId("")
                                                                setItems([])
                                                                toast({
                                                                    title: "Success",
                                                                    description: "Inventory added successfully",
                                                                    duration: 5000,
                                                                })
                                                                setLoading(false)
                                                            },
                                                            (e) => {
                                                                console.log(e)
                                                                toast({
                                                                    title: "Error",
                                                                    description: "Failed to complete inventory. Please try again later. If the problem persists, contact support.",
                                                                    duration: 5000,
                                                                })
                                                                setLoading(false)
                                                            }
                                                        )
                                                    })
                                                } else {
                                                    return
                                                }
                                            } catch (error) {
                                                setLoading(false)
                                                toast({
                                                    title: "Error",
                                                    description: "Failed to add inventory. Please try again later. If the problem persists, contact support.",
                                                    duration: 5000,
                                                })
                                            }
                                        }}
                                    >
                                        <ArrowBigRightDashIcon className='text-white w-4 h-4 mr-2' />
                                        Add Inventory
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </ResizablePanel>
                    <ResizableHandle withHandle />
                    <ResizablePanel minSize={20} maxSize={40} defaultSize={30} className="w-[350px] 2xl:w-[400px] overflow-auto">
                        <Add
                            key={items.length}
                            items={items}
                            editItem={(formObjItem, dbItemId) => {
                                updateAddStockItem(pglite, {
                                    dbId: dbItemId,
                                    batch_no: formObjItem.batchNo,
                                    cost: formObjItem.cost.value,
                                    bonus: formObjItem.bonus.value,
                                    custom_barcode: formObjItem.customBarcode,
                                    discount_type: formObjItem.discountType,
                                    discount_value: formObjItem.discountValue.value,
                                    expiry_date: formObjItem.expiryDate ? dayjs(formObjItem.expiryDate).format("YYYY-MM-DD") : dayjs().add(1, "year").format("YYYY-MM-DD"),
                                    price: formObjItem.price.value || 0,
                                    production_date: dayjs(formObjItem.productionDate!).format("YYYY-MM-DD"),
                                    quantity: formObjItem.quantity.value,
                                    type: formObjItem.type,
                                    vat: formObjItem.vat
                                },
                                    "ADD_STOCK",
                                    () => {
                                        const newItem = formObjToAddedItems(formObjItem, dbItemId)
                                        setItems(items.map((item) => {
                                            if (item.db_item_id === dbItemId) {
                                                return newItem
                                            }
                                            return item
                                        }))
                                    },
                                    (e) => {
                                        console.log(e)
                                        toast({
                                            title: "Error",
                                            description: "Failed to edit item. Please try again later. If the problem persists, contact support.",
                                            duration: 5000,
                                        })
                                    }
                                )
                            }}
                            injectItem={editItem}
                            addItem={async (formObjItem) => {
                                let copied_instanceId = instanceId
                                if (copied_instanceId === "") {
                                    const res = await pglite.query<{
                                        id: string
                                    }>("INSERT INTO add_stock_instance (complete, updated_at) VALUES (false, NOW()) RETURNING id")
                                    copied_instanceId = res.rows[0].id
                                    setInstanceId(copied_instanceId)
                                }
                                addAddStockItem(pglite, {
                                    product_name: formObjItem.trade_name,
                                    batch_no: formObjItem.batchNo,
                                    cost: formObjItem.cost.value,
                                    bonus: formObjItem.bonus.value,
                                    custom_barcode: formObjItem.customBarcode,
                                    discount_type: formObjItem.discountType,
                                    discount_value: formObjItem.discountValue.value,
                                    expiry_date: formObjItem.expiryDate ? dayjs(formObjItem.expiryDate).format("YYYY-MM-DD") : dayjs().add(1, "year").format("YYYY-MM-DD"),
                                    price: formObjItem.price.value || 0,
                                    production_date: dayjs(formObjItem.productionDate!).format("YYYY-MM-DD"),
                                    quantity: formObjItem.quantity.value,
                                    type: formObjItem.type,
                                    vat: formObjItem.vat,
                                    instanceId: Number(copied_instanceId),
                                    item_id: formObjItem.item_id,
                                },
                                    "ADD_STOCK"
                                    ,
                                    (v) => {
                                        console.log("new id  = ", v)
                                        const newItem = formObjToAddedItems(formObjItem, v!)
                                        setItems([...items, newItem])
                                    },
                                    () => {
                                        toast({
                                            title: "Error",
                                            description: "Failed to add item. Please try again later. If the problem persists, contact support.",
                                            duration: 5000,
                                        })
                                    }
                                )
                            }}
                        />
                    </ResizablePanel>
                </ResizablePanelGroup>
            </div>
        </Dialog>
    )
}

export default Component