import "./index.css";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider, ErrorBoundary } from "@rollbar/react";
import { OidcProvider } from "./oidc";

import { routeTree } from "./routeTree.gen";
import { Toaster } from "./components/ui/toaster";
import { TooltipProvider } from "./components/ui/tooltip";

import { PGlite, IdbFs } from "@electric-sql/pglite"
import { PGliteProvider } from "@electric-sql/pglite-react"
import { live } from '@electric-sql/pglite/live'

const pg = new PGlite("idb://neulink-pos")

const db = await PGlite.create({
  extensions: { live },
  fs: new IdbFs("neulink-pos"),
  relaxedDurability: true,
})

const router = createRouter({ routeTree });

const container = document.getElementById("root");

if (container === null) {
  throw new Error("app element does not exists.");
}

createRoot(container).render(
  <StrictMode>
    <Provider
      config={{
        accessToken: "78bfb0f7d89e400487b49f4795130cfd",
        environment: process.env.NODE_ENV || "development",
        captureDeviceInfo: true,
        captureIp: true,
        captureUnhandledRejections: true,
        captureUncaught: true,
        enabled: process.env.NODE_ENV === "production",
        payload: {
          client: {
            javascript: {
              code_version: import.meta.env.VITE_APP_VERSION,
              source_map_enabled: true,
              guess_uncaught_frames: true
            }
          }
        }
      }}
    >
      <ErrorBoundary>
        <OidcProvider>
          <Toaster />
          <PGliteProvider db={db}>
          <TooltipProvider>
            <RouterProvider router={router} />
          </TooltipProvider>
          </PGliteProvider>
        </OidcProvider>
      </ErrorBoundary>
    </Provider>
  </StrictMode>
);
