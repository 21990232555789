import { PGlite } from "@electric-sql/pglite";
import { PGliteWithLive } from "@electric-sql/pglite/live";

export type PGRunner = PGliteWithLive | PGlite


export type addAddStockParams = {
    instanceId: number,
    item_id?: string,
    product_name?: string,
    type?: string,
    batch_no?: string,
    cost?: number,
    price?: number,
    expiry_date?: string,
    production_date?: string,
    custom_barcode?: string,
    discount_type?: string,
    vat?: number,
    discount_value?: number,
    quantity?: number,
    bonus?: number
}
//float values returned as string
export type AddStockItemRow = {
    id: number;
    instance_id: number;
    item_id: string;
    product_name: string;
    type: string;
    batch_no: string;
    cost: string;
    price: string;
    expiry_date: Date;
    production_date: Date;
    custom_barcode: string;
    discount_type: string;
    vat: string;
    discount_value: string;
    quantity: number;
    bonus: number;
    instance_type: string;
};

export type AddStockInstanceRow = {
    id: number;
    complete: boolean;
    updated_at: string;
};

export function addAddStockItem(pglite: PGRunner, params: addAddStockParams, type: "ADD_STOCK" | "CREATE_PI" | "DRAFT_PI", onSuccess: (itemDBId: number) => void, onError?: (error: any) => void) {
    const q = `
    INSERT INTO add_stock_item
    (instance_id,item_id,product_name,batch_no,cost,price,expiry_date,production_date,discount_type,discount_value,quantity,bonus,custom_barcode,vat,type,instance_type)
    VALUES 
    ($1,$2,$3,$4,$5,$6,$7,$8,$9,$10,$11,$12,$13,$14,$15,$16)
    RETURNING id
    `;
    pglite
        .query<{
            id: number;
        }>(q, [params.instanceId, params.item_id, params.product_name, params.batch_no, params.cost, params.price, params.expiry_date, params.production_date, params.discount_type, params.discount_value, params.quantity, params.bonus, params.custom_barcode, params.vat, params.type, type])
        .then(rows => {
            onSuccess(rows.rows[0].id);
        })
        .catch(onError ? onError : () => { })
}


export function updateAddStockItem(pglite: PGRunner, params: {
    dbId: number,
    type: string,
    batch_no: string,
    cost: number,
    price: number,
    expiry_date: string,
    production_date: string,
    custom_barcode: string,
    discount_type: string,
    vat: number,
    discount_value: number,
    quantity: number,
    bonus: number
}, type: "ADD_STOCK" | "CREATE_PI" | "DRAFT_PI", onSuccess: () => void, onError?: (error: any) => void) {
    const q = `
      UPDATE add_stock_item
      SET
      batch_no = $1,
      cost = $2,
      price = $3,
      expiry_date = $4,
      production_date = $5,
      discount_type = $6,
      discount_value = $7,
      quantity = $8,
      bonus = $9,
      custom_barcode = $10,
      vat = $11,
      type = $12
      WHERE id = $13 AND instance_type = $14
    `
    pglite
        .query(q, [params.batch_no, params.cost, params.price, params.expiry_date, params.production_date, params.discount_type, params.discount_value, params.quantity, params.bonus, params.custom_barcode, params.vat, params.type, params.dbId, type])
        .then(onSuccess)
        .catch(onError ? onError : () => { })
}

export function deleteAddStockItem(pglite: PGRunner, dbId: number, onSuccess: () => void, onError?: (error: any) => void) {
    const q = `DELETE FROM add_stock_item WHERE id = ${dbId}`
    pglite.exec(q).catch(onError ? onError : () => { }).then(onSuccess)
}