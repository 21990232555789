import { BadgeCheck, Ban, MoveUpRight, Redo2 } from "lucide-react";
import { Button } from "../ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
  CardContent,
} from "../ui/card";
import { Route as salesOneRoute } from "@/routes/overview/sales_history/$saleId";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { useState } from "react";
import { markSaleAsRefunded, markSaleAsVoid } from "@/screens/overview/sales-history/sale-info/api";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { toast } from "../ui/use-toast";

export default function QuickActions({partialMedicalItemsSelected, partialNonMedicalItemsSelected}: {partialNonMedicalItemsSelected: string[], partialMedicalItemsSelected: string[]}) {
  const data = salesOneRoute.useLoaderData();
  const navigate = useNavigate();
  const router = useRouter();
  const searchParams = salesOneRoute.useSearch();
  const [voidTransactionLoading, setVoidTransactionLoading] = useState(false);
  
  return (
    <Card className="mt-6 sticky bottom-0 overflow-hidden rounded-xl shadow-transparent ml-4">
      <CardContent className="flex gap-2 p-4 space-y-0">
        <Button
          size="sm"
          variant="ghost"
          className="gap-1 bg-blue-700 text-white border border-gray-200 rounded-lg"
          disabled={data.sale_status.void || data.sale_status.refunded}
          onClick={() =>
            navigate({
              to: "/receipt/$saleId",
              search: {
                return_to: "sales_history_one",
              },
            })
          }
        >
          <BadgeCheck className="p-1"></BadgeCheck>
          Show Receipt
        </Button>
        <Popover>
          <PopoverTrigger disabled={data.sale_status.void || data.sale_status.refunded}>
            <Button
            size="sm"
            variant="ghost"
            className="gap-1 bg-red-600 border border-gray-200 rounded-lg justify-left items-left text-white"
            disabled={voidTransactionLoading || data.sale_status.void || data.sale_status.refunded}
          >
            <Ban className="p-1"></Ban>
            {voidTransactionLoading ? "Voiding Transaction..." : "Void Transaction"}
          </Button>
          <PopoverContent>
            <p>Are you sure you want to void this sale?</p>
            <div className="flex gap-2">
              <Button
                size="sm"
                variant="ghost"
                className="bg-red-600 text-white border border-gray-200 rounded-lg"
                disabled={voidTransactionLoading}
                onClick={async () => {
                  setVoidTransactionLoading(true);
                  await markSaleAsVoid(data.sale_id);
                  setVoidTransactionLoading(false);
                  router.invalidate();
                }}
              >
                {voidTransactionLoading ? "Voiding Transaction..." : "Yes"}
              </Button>
              <Button
                size="sm"
                variant="ghost"
                className="bg-gray-200 text-black border border-gray-200 rounded-lg"
              >
                No
              </Button>
            </div>
          </PopoverContent>
          </PopoverTrigger>
        </Popover>
        {data.is_valid_patient && <Button
          size="sm"
          variant="ghost"
          className="gap-1 w-auto p-3 bg-white border border-gray-200 rounded-lg"
          onClick={() => {
            navigate({
              to: "/patients/patient-info/$patientId",
              params: { patientId: data.patient_info.patient_id },
            });
          }}
        >
          <MoveUpRight className="p-1"></MoveUpRight>
          View Other Transactions
        </Button>}
        <Popover>
          <PopoverTrigger disabled={data.sale_status.void || data.sale_status.refunded}>
            <Button
              size="sm"
              variant="ghost"
              className="gap-1 w-auto p-3 text-black border border-gray-200 rounded-lg"
              disabled={voidTransactionLoading || data.sale_status.void || data.sale_status.refunded}
            >
              <Redo2 className="p-1"></Redo2>
              Return & Refund
            </Button>
          <PopoverContent>
            <p>Are you sure you want to return and refund?</p>
            <p>{
              (partialMedicalItemsSelected.length === 0 && partialNonMedicalItemsSelected.length === 0) ? "All Items will be refunded. No New Sale will be created." : "Selected Items will be refunded. New Sale will be created."
            }</p>
            <p>{
              (partialMedicalItemsSelected.length > 0 || partialNonMedicalItemsSelected.length > 0) ? `Selected Medical Items: ${partialMedicalItemsSelected.length}, Selected Non-Medical Items: ${partialNonMedicalItemsSelected.length}` : ""
            }</p>
            <div className="flex gap-2">
              <Button
                size="sm"
                variant="ghost"
                className="bg-red-600 text-white border border-gray-200 rounded-lg"
                disabled={voidTransactionLoading}
                onClick={async () => {
                  try {
                    setVoidTransactionLoading(true);
                    const newSaleID = await markSaleAsRefunded(data.sale_id, partialMedicalItemsSelected, partialNonMedicalItemsSelected);
                    setVoidTransactionLoading(false);
                    if (newSaleID) {
                      toast({
                        title: "Success",
                        description: "Transaction refunded successfully. Navigating to New Sale",
                      })
                      navigate({
                        to: "/overview/sales_history/$saleId",
                        params: { saleId: newSaleID },
                      });
                    } else {
                      toast({
                        title: "Success",
                        description: "Sale refunded successfully",
                      })
                      router.invalidate();
                    }
                  } catch (e) {
                    toast({
                      title: "Error",
                      description: "Failed to refund transaction",
                    })
                    console.error(e);
                    setVoidTransactionLoading(false);
                  }
                }}
              >
                {voidTransactionLoading ? "Voiding Transaction..." : "Yes"}
              </Button>
              <Button
                size="sm"
                variant="ghost"
                className="bg-gray-200 text-black border border-gray-200 rounded-lg"
              >
                No
              </Button>
            </div>
          </PopoverContent>
          </PopoverTrigger>
        </Popover>
      </CardContent>
    </Card>
  );
};
