import {z} from 'zod';

export const PharmacyInfo = z.object({
    location: z.object({
        address: z.string(),
        emirate: z.string(),
        latitude: z.number(),
        longitude: z.number(),
    }),
    metadata: z.object({
        email: z.string(),
        logo_url: z.string(),
        pharmacy_name: z.string(),
        phone_number: z.string(),
        trn: z.string(),
        whatsapp_phone: z.string(),
    }),
    pharmacy_code: z.string(),
    settings: z.object({
        invoice_date_format: z.string(),
        invoice_number_padding: z.number(),
        invoice_number_padding_character: z.string(),
        invoice_number_prefix: z.string(),
        invoice_number_suffix: z.string(),
        invoice_time_format: z.string(),
        print_barcode_on_invoice: z.boolean(),
        print_invoice_date_on_invoice: z.boolean(),
        print_invoice_number_on_invoice: z.boolean(),
        print_invoice_time_on_invoice: z.boolean(),
        print_invoice_total_on_invoice: z.boolean(),
        printer_paper_width: z.number(),
        printer_paper_width_unit: z.string(),
        printer_type: z.string(),
    }),
})
export const SaleOne = z.object({
    card_details: z.object({
        auth_code: z.string(),
        card_number: z.string(),
    }),
    insurance_details: z.object({
        erx_number: z.string(),
        insurance_id: z.string(),
        insurance_name: z.string(),
    }),
    is_valid_insurance: z.boolean(),
    is_valid_patient: z.boolean(),
    created_at: z.string(),
    medical_items: z.array(z.object({
        barcode: z.string(),
        batch_number: z.string(),
        expiry_date: z.string(),
        medical_metadata: z.object({
            ddc_code: z.string(),
            ddc_last_updated: z.string(),
            ingredient: z.string(),
            manufacturer: z.string(),
            owner: z.string(),
            route_of_administration: z.string(),
            scientific_name: z.string(),
            source: z.string(),
            strength: z.string(),
        }),
        price: z.number(),
        sale_metadata: z.object({
            discount_amount: z.number(),
            insurance_amount: z.number(),
            insurance_used: z.boolean(),
            refunded: z.boolean(),
        }),
        sale_quantity: z.number(),
        sale_row_id: z.string(),
        trade_name: z.string(),
        unit: z.number(),
        unit_type: z.string(),
        vat: z.number(),
    })),
    non_medical_items: z.array(z.object({
        barcode: z.string(),
        batch_number: z.string(),
        expiry_date: z.object({
            time: z.string(),
            valid: z.boolean(),
        }),
        non_medical_metadata: z.object({
            category: z.string(),
            manufacturer: z.string(),
            owner: z.string(),
            sub_category: z.string(),
        }),
        price: z.number(),
        sale_metadata: z.object({
            discount_amount: z.number(),
            insurance_amount: z.number(),
            insurance_used: z.boolean(),
            refunded: z.boolean(),
        }),
        sale_quantity: z.number(),
        sale_row_id: z.string(),
        trade_name: z.string(),
        unit: z.number(),
        unit_type: z.string(),
        vat: z.number(),
    })),
    patient_info: z.object({
        emirates_id: z.string(),
        metadata: z.object({
            address: z.string(),
        }),
        name: z.string(),
        patient_id: z.string(),
        phone: z.string(),
    }),
    pharmacy_info: PharmacyInfo,
    sale_amounts: z.object({
        card: z.number(),
        cash: z.number(),
        cash_received: z.number(),
        insurance_amount: z.number(),
        total_discount: z.number(),
        vat: z.number(),
    }),
    sale_id: z.string(),
    sale_payment_type: z.enum(["CASH", "CARD", "ON_HOLD", "SPLIT"]),
    sale_status: z.object({
        paid: z.boolean(),
        refunded: z.boolean(),
        void: z.boolean(),
    }),
    user_info: z.object({
        email: z.string(),
        first_name: z.string(),
        last_name: z.string(),
        user_id: z.string(),
        username: z.string(),
    }),
    invoice_id: z.string()
});

export const SearchSchemaReceipt = z.object({
    return_to: z.enum(["sales_history", "sales", "sales_history_one"]),
})
  
export type SearchSchemaReceipt = z.infer<typeof SearchSchemaReceipt>

export type SaleOne = z.infer<typeof SaleOne>;

export type PharmacyInfo = z.infer<typeof PharmacyInfo>;