import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/invoicing/component";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import dayjs from "dayjs";
import {
  InvoicingOverview,
  InvoicingRevevue,
} from "@/screens/invoicing/components/data/schema";
import { Loading } from "@/layouts/loading";
import { ListOfReportsSchema } from "@/components/command-modal/models";

const API_URL = import.meta.env.VITE_API_URL as string;

type Props = {
  overview: InvoicingOverview;
  revenue: InvoicingRevevue;
  reportsList: ListOfReportsSchema[];
};

// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";

export const Route = createFileRoute("/invoicing/")({
  component: Component,
  loader: async (): Promise<Props> => {
    const response_overview = await httpClient.post(
      API_URL + "/public/pharmacy/overview/invoicing",
      {
        pharmacy_code: getPharmacyID(),
        month: parseInt(dayjs().format("MM")),
        year: parseInt(dayjs().format("YYYY")),
      }
    );
    const response_overview_data = response_overview.data;
    if (!response_overview_data.status) {
      throw new Error("API Fetch Error");
    }

    const response_revenue = await httpClient.post(
      API_URL + "/public/pharmacy/overview/revenue",
      {
        pharmacy_code: getPharmacyID(),
        year: parseInt(dayjs().format("YYYY")),
      }
    );
    const response_revenue_data = response_revenue.data;
    if (!response_revenue_data.status) {
      throw new Error("API Fetch Error");
    }

    const response = await httpClient.get(
      `${import.meta.env.VITE_AI_API_URL as string}/available_intents`
    );
    const reportsData = response.data;

    return {
      overview: response_overview_data.data as InvoicingOverview,
      revenue: response_revenue_data.data as InvoicingRevevue,
      reportsList: reportsData as ListOfReportsSchema[],
    };
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
});
