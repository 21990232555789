import { dateToDB } from "../time_format";
import { PGRunner } from "./addStock";

export type PIInfoRow = Partial<{
    invoice_date: Date;
    invoice_number: string;
    delivery_date: Date;
    payment_mode: string;
    payment_type: string;
    supplier_id: string;
}> & { id: number, instance_id: number };

export type DraftPIInstanceRow = {
    id: number;
    updated_at: string;
    completed: boolean;
    po_id: string;
}


export function setPIInfo(pglite: PGRunner, params: Omit<PIInfoRow, "instance_id" | "id"> & { instance_id?: number }, onSuccess: (instance_id: number) => void, onError?: (error: any) => void) {
    console.log(params)
    const instance_id = params.instance_id
    if (instance_id) {
        const q = `
      UPDATE create_pi_info
      SET
      invoice_date = $1,
      invoice_number = $2,
      delivery_date = $3,
      payment_mode = $4,
      payment_type = $5,
      supplier_id = $6
      WHERE instance_id = $7 AND instance_type = 'CREATE_PI'
    `
        pglite
            .query(q, [dateToDB(params.invoice_date), params.invoice_number, dateToDB(params.delivery_date), params.payment_mode, params.payment_type, params.supplier_id, instance_id])
            .then(() => {
                onSuccess(instance_id)
            })
            .catch(onError ? onError : () => { })
        return
    }
    pglite.transaction(async (tx) => {
        const instance_id = (await tx.query<{ id: number }>("INSERT INTO create_pi_instance (complete,updated_at) VALUES (FALSE,NOW()) ON CONFLICT DO NOTHING RETURNING id")).rows[0].id

        const q = `
        INSERT INTO create_pi_info
        (instance_id,invoice_date,invoice_number,delivery_date,payment_mode,payment_type,supplier_id,instance_type)
        VALUES
        ($1,$2,$3,$4,$5,$6,$7,'CREATE_PI')
        `
        await tx
            .query(q, [instance_id, dateToDB(params.invoice_date), params.invoice_number, dateToDB(params.delivery_date), params.payment_mode, params.payment_type, params.supplier_id])
        return instance_id
    }).catch(onError ? onError : () => { }).then((v) => {
        onSuccess(v!)
    })
}
export function setDraftPIInfo(pglite: PGRunner, params: Omit<PIInfoRow, "instance_id" | "id"> & { instance_id?: number }, poId: string, onSuccess: (instance_id: number) => void, onError?: (error: any) => void) {
    console.log(params)
    const instance_id = params.instance_id
    if (instance_id) {
        const q = `
      UPDATE create_pi_info
      SET
      invoice_date = $1,
      invoice_number = $2,
      delivery_date = $3,
      payment_mode = $4,
      payment_type = $5,
      supplier_id = $6
      WHERE instance_id = $7 AND instance_type = 'DRAFT_PI'
    `
        pglite
            .query(q, [dateToDB(params.invoice_date), params.invoice_number, dateToDB(params.delivery_date), params.payment_mode, params.payment_type, params.supplier_id, instance_id])
            .then(() => {
                onSuccess(instance_id)
            })
            .catch(onError ? onError : () => { })
        return
    }
    pglite.transaction(async (tx) => {
        const instance_id = (await tx.query<{ id: number }>("INSERT INTO draft_pi_instance (complete,updated_at,po_id) VALUES (FALSE,NOW(),$1) ON CONFLICT DO NOTHING RETURNING id", [poId])).rows[0].id
        const q = `
        INSERT INTO create_pi_info
        (instance_id,invoice_date,invoice_number,delivery_date,payment_mode,payment_type,supplier_id,instance_type)
        VALUES
        ($1,$2,$3,$4,$5,$6,$7,'DRAFT_PI')
        `
        await tx
            .query(q, [instance_id, dateToDB(params.invoice_date), params.invoice_number, dateToDB(params.delivery_date), params.payment_mode, params.payment_type, params.supplier_id])
        return instance_id
    }).catch(onError ? onError : () => { }).then((v) => {
        onSuccess(v!)
    })
}
export function completeDraftPI(pglite: PGRunner, instanceId: number, onSuccess: () => void, onError?: (error: any) => void) {
    pglite.transaction(async (tx) => {
        tx.exec(`DELETE FROM draft_pi_instance WHERE id = ${instanceId}`)
        tx.exec(`DELETE FROM create_pi_info WHERE instance_id = ${instanceId} AND instance_type = 'DRAFT_PI'`)
        tx.exec(`DELETE FROM add_stock_item WHERE instance_id = ${instanceId} AND instance_type = 'DRAFT_PI'`)
    })
}
export function completeCreatePIInstance(pglite: PGRunner, instanceId: number, onSuccess: () => void, onError?: (error: any) => void) {
    console.log(instanceId)
    const deleteInstanceQuery = `UPDATE create_pi_instance SET complete = TRUE, updated_at = NOW() WHERE id = ${instanceId}`
    const deleteItemsQuery = `DELETE FROM add_stock_item WHERE instance_id = ${instanceId} AND instance_type = 'CREATE_PI'`
    const deleteInfoQuery = `DELETE FROM create_pi_info WHERE instance_id = ${instanceId} AND instance_type = 'CREATE_PI'`
    pglite.transaction(async (tx) => {
        await tx.exec(deleteItemsQuery)
        await tx.exec(deleteInfoQuery)
        await tx.exec(deleteInstanceQuery)
    }).then(onSuccess).catch(onError ? onError : () => { })
}

export function completeAddStockInstance(pglite: PGRunner, instanceId: number, onSuccess: () => void, onError?: (error: any) => void) {
    const deleteInstanceQuery = `UPDATE add_stock_instance SET complete = TRUE, updated_at = NOW() WHERE id = ${instanceId}`
    const deleteItemsQuery = `DELETE FROM add_stock_item WHERE instance_id = ${instanceId} AND instance_type = 'ADD_STOCK'`
    pglite.transaction(async (tx) => {
        await tx.exec(deleteItemsQuery)
        await tx.exec(deleteInstanceQuery)
    }).then(onSuccess).catch(onError ? onError : () => { })
}
