import React from 'react'
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
  } from "@/components/ui/breadcrumb"
  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table" 
import { CheckIcon, FilePenLine } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Separator } from "@/components/ui/separator"
import { Route as singleItemInventory } from '@/routes/inventory/item/$type.$itemId'
import { useNavigate, useRouter } from '@tanstack/react-router'
import { convertToCurrencyRounded } from '@/utils/currency_converter'
import dayjs from 'dayjs'
import { Loading } from '@/layouts/loading'
import { acceptMedicalPendingPriceIndividual } from './api'
import EditItem from './components/edit-item'
import OfferItem from './components/offer-item'

const Component = () => {
    const searchParams = singleItemInventory.useSearch();
    const pathParams = singleItemInventory.useParams();
    const data = singleItemInventory.useLoaderData();
    const navigate = useNavigate();
    return (
        <div className='h-full'>
            <Breadcrumb>
                <BreadcrumbList>
                    <BreadcrumbItem>
                        <BreadcrumbLink onClick={() => {
                            navigate({
                                to: '/inventory'
                            })
                        }} style={{
                            cursor: 'pointer'
                        }}>Inventory Overview</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <BreadcrumbLink onClick={() => {
                            navigate({
                                to: '/inventory/available-inventory',
                                search: searchParams
                            })
                        }} style={{
                            cursor: 'pointer'
                        }}>All Inventory</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <BreadcrumbPage>{data.itemData.item_data.trade_name}</BreadcrumbPage>
                    </BreadcrumbItem>
                </BreadcrumbList>
            </Breadcrumb>
            <hr className='my-4' />

            {pathParams.type === 'MEDICAL' ? <MedicalItemComponent /> : <NonMedicalItemComponent />}
            
        </div>
    )
}

const MedicalItemComponent = () => {
    const data = singleItemInventory.useLoaderData();
    const router = useRouter()
    const [pricingLoading, setPricingLoading] = React.useState(false)
    return (
        <div className='grid grid-cols-8 space-x-4 h-full'>
                <div className='col-span-5 p-4 h-full'>
                    <div>
                        <p className="font-semibold">Your Inventory</p>
                        <p className="text-gray-500">
                            Stock info in relation with your pharmacy
                        </p>
                        <hr className='my-2' />
                        <Table className='mt-4'>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Batch</TableHead>
                                    <TableHead>Stock</TableHead>
                                    <TableHead>Quantity</TableHead>
                                    <TableHead>Bonus</TableHead>
                                    <TableHead>Cost</TableHead>
                                    <TableHead>Expiry</TableHead>
                                    <TableHead>Last Sold On</TableHead>
                                    <TableHead></TableHead>
                                </TableRow>
                            </TableHeader>
                           <TableBody>
                                    {data.inventoryData.type === "MEDICAL" && data.inventoryData.inventory_data.map((item, index) => <TableRow key={index} style={{
                                        opacity: item.available_stock === 0 || dayjs(item.expiry_date).isBefore(dayjs()) ? 0.5 : 1,
                                        cursor: item.available_stock === 0 || dayjs(item.expiry_date).isBefore(dayjs()) ? 'not-allowed' : 'default'
                                    }}>
                                        <TableCell>{item.batch_number}</TableCell>
                                        <TableCell>{item.available_stock}</TableCell>
                                        <TableCell>{item.quantity.original}</TableCell>
                                        <TableCell>{item.quantity.bonus}</TableCell>
                                        <TableCell>{convertToCurrencyRounded(item.cost)}</TableCell>
                                        <TableCell>{dayjs(item.expiry_date).format("DD MMM YYYY")}</TableCell>
                                        <TableCell>{item.last_sold_date ? dayjs(item.last_sold_date).format("DD/MM/YYYY") : "Not Available"}</TableCell>
                                        <TableCell>
                                            <OfferItem inventory_id={item.inventory_id} type='MEDICAL'/>
                                            <EditItem itemId={item.inventory_id} type={data.inventoryData.type}
                                                disabled={item.available_stock === 0 || dayjs(item.expiry_date).isBefore(dayjs())}
                                            />
                                        </TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table>
                        <hr className='my-4' />
                        <div className='flex items-center justify-between'>
                            <div>
                                <p className="font-semibold">Past price updates</p>
                                <p className="text-gray-500">
                                    Ministry Pricing Updates
                                </p>
                            </div>
                            <Button variant={'secondary'}>
                                Request for a Price Update
                            </Button>
                        </div>
                        <hr className='my-4' />
                        <div className='flex flex-col space-y-8'>
                            {data.pricingHistoryMaster.map((price, index, array) => (
                                <div className='flex gap-x-4' key={index}>
                                    <p className='text-gray-600'>{dayjs(price.effective_from).format("DD MMM YYYY")}</p>
                                    <Separator className='mx-4 h-[120px] w-1 bg-gray-500' orientation="vertical" />
                                    <div>
                                        <p className='font-semibold text-gray-700'>{convertToCurrencyRounded(price.price)} {index < array.length - 1 ? `| ${price.price - array[index+1].price > 0 ? 'Increased By' : 'Decreased By'} ${convertToCurrencyRounded(price.price - array[index+1].price)}` : null}</p>
                                        <p className='text-gray-600 text-sm'>Applicable Emirates: {price.associated_emirates.join(", ")}</p>
                                        {!data.pricingHistoryPharmacy.find(p => p.medical_history_id === price.id) ? <div className='flex items-center space-x-4 mt-4'>
                                            <Button 
                                                variant='link'
                                                className='text-gray-600 underline text-sm'
                                                disabled={true}
                                            >
                                                Ignore for now
                                            </Button>
                                            <Button
                                                variant={'outline'}
                                                className='text-blue-700'
                                                disabled={pricingLoading}
                                                onClick={async () => {
                                                    setPricingLoading(true)
                                                    const status = await acceptMedicalPendingPriceIndividual(price.id)
                                                    if (status) {
                                                        router.invalidate()
                                                    }
                                                    setPricingLoading(false)
                                                }}
                                            >
                                                <CheckIcon className='w-4 h-4 mr-2' />
                                                {pricingLoading ? <Loading /> : "Accept price change"}
                                            </Button>
                                        </div> : data.pricingHistoryPharmacy.length > 0 && data.pricingHistoryPharmacy[0].medical_history_id === price.id ? <div className='flex items-center space-x-4 mt-4'>
                                                <p className='text-gray-600 text-sm'>Current Active Price for your Pharmacy <br /> Effective from {dayjs(data.pricingHistoryPharmacy.find(p => p.medical_history_id === price.id)?.effective_from).format("DD MMM YYYY")}</p>
                                            </div> : <div className='flex items-center space-x-4 mt-4'>
                                                    <p className='text-gray-600 text-sm'>Past Active Price <br />Effective From {dayjs(data.pricingHistoryPharmacy.find(p => p.medical_history_id === price.id)?.effective_from).format("DD MMM YYYY")} to {dayjs(data.pricingHistoryPharmacy.find(p => p.medical_history_id === array[index-1].id)?.effective_from).format("DD MMM YYYY")}</p>
                                                </div>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='col-span-3 p-4 bg-gray-400/10 rounded-lg h-full'>
                    <p className="font-semibold">Item Details</p>
                    <p className="text-gray-500">
                        Details acquired from DDC
                    </p>
                    <hr className='my-2' />
                    <div className='text-sm'>
                        <p className='text-gray-500'>Trade Name</p>
                        <p className='font-semibold'>{data.itemData.item_data.trade_name}</p>
                    </div>
                    <hr className='my-2' />
                    <div className='text-sm'>
                        <p className='text-gray-500'>Scientific Name</p>
                        <p className='font-semibold'>{data.itemData.type === "MEDICAL" ? data.itemData.item_data.metadata.scientific_name : null}</p>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Barcode</p>
                            <p className='font-semibold underline'>{data.itemData.item_data.barcode ? data.itemData.item_data.barcode : "N/A"}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>DDC Code</p>
                            <p className='font-semibold'>{data.itemData.type === "MEDICAL" ? data.itemData.item_data.metadata.ddc_code : null}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Last updated by DDC</p>
                            <p className='font-semibold'>{data.itemData.type === "MEDICAL" ? data.itemData.item_data.metadata.ddc_last_updated : null}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Ingredient</p>
                            <p className='font-semibold'>{data.itemData.type === "MEDICAL" ? data.itemData.item_data.metadata.ingredient : null}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Manufacturer</p>
                            <p className='font-semibold'>{data.itemData.type === "MEDICAL" ? data.itemData.item_data.metadata.manufacturer : null}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Owner</p>
                            <p className='font-semibold'>{data.itemData.type === "MEDICAL" ? data.itemData.item_data.metadata.owner : null}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Source</p>
                            <p className='font-semibold'>{data.itemData.type === "MEDICAL" ? data.itemData.item_data.metadata.source : null}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Strength</p>
                            <p className='font-semibold'>{data.itemData.type === "MEDICAL" ? data.itemData.item_data.metadata.strength : null}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Unit</p>
                            <p className='font-semibold'>{data.itemData.item_data.unit}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Unit Type</p>
                            <p className='font-semibold'>{data.itemData.item_data.unit_type}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Route of Administration</p>
                            <p className='font-semibold'>{data.itemData.type === "MEDICAL" ? data.itemData.item_data.metadata.route_of_administration : null}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>VAT %</p>
                            <p className='font-semibold'>{data.itemData.item_data.vat}%</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                </div>
            </div>
    )
}

const NonMedicalItemComponent = () => {
    const data = singleItemInventory.useLoaderData();
    return (
        <div className='grid grid-cols-8 space-x-4 h-full'>
                <div className='col-span-5 p-4 h-full'>
                    <div>
                        <p className="font-semibold">Your Inventory</p>
                        <p className="text-gray-500">
                            Stock info in relation with your pharmacy
                        </p>
                        <hr className='my-2' />
                        <Table className='mt-4'>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Batch</TableHead>
                                    <TableHead>Stock</TableHead>
                                    <TableHead>Quantity</TableHead>
                                    <TableHead>Bonus</TableHead>
                                    <TableHead>Price</TableHead>
                                    <TableHead>Cost</TableHead>
                                    <TableHead>Expiry</TableHead>
                                    <TableHead>Last Sold On</TableHead>
                                    <TableHead></TableHead>
                                </TableRow>
                            </TableHeader>
                           <TableBody>
                                    {data.inventoryData.type === "NON_MEDICAL" && data.inventoryData.inventory_data.map((item, index) => <TableRow key={index} style={{
                                        opacity: item.available_stock === 0 || dayjs(typeof item.expiry_date === "string" ? item.expiry_date : item.expiry_date.Valid ? item.expiry_date.Time : null).isBefore(dayjs()) ? 0.5 : 1,
                                        cursor: item.available_stock === 0 || dayjs(typeof item.expiry_date === "string" ? item.expiry_date : item.expiry_date.Valid ? item.expiry_date.Time : null).isBefore(dayjs()) ? 'not-allowed' : 'default'
                                    }}>
                                        <TableCell>{item.batch_number}</TableCell>
                                        <TableCell>{item.available_stock}</TableCell>
                                        <TableCell>{item.quantity.original}</TableCell>
                                        <TableCell>{item.quantity.bonus}</TableCell>
                                        <TableCell>{convertToCurrencyRounded(item.price)}</TableCell>
                                        <TableCell>{convertToCurrencyRounded(item.cost)}</TableCell>
                                        <TableCell>{typeof item.expiry_date === "string" ? dayjs(item.expiry_date).format("DD MMM YYYY") : item.expiry_date.Valid ? dayjs(item.expiry_date.Time).format("DD MMM YYYY") : "No Expiry"}</TableCell>
                                        <TableCell>{item.last_sold_date ? dayjs(item.last_sold_date).format("DD/MM/YYYY") : "Not Available"}</TableCell>
                                        <TableCell>
                                            <OfferItem inventory_id={item.non_medical_inventory_id} type='NON_MEDICAL'/>
                                            <EditItem itemId={item.non_medical_inventory_id} type={data.inventoryData.type} 
                                                disabled={item.available_stock === 0 || dayjs(typeof item.expiry_date === "string" ? item.expiry_date : item.expiry_date.Valid ? item.expiry_date.Time : null).isBefore(dayjs())}
                                            />
                                        </TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table>
                    </div>
                </div>

                <div className='col-span-3 p-4 bg-gray-400/10 rounded-lg h-full'>
                    <p className="font-semibold">Item Details</p>
                    <p className="text-gray-500">
                        Details entered during item creation
                    </p>
                    <hr className='my-2' />
                    <div className='text-sm'>
                        <p className='text-gray-500'>Trade Name</p>
                        <p className='font-semibold'>{data.itemData.item_data.trade_name}</p>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Category</p>
                            <p className='font-semibold'>{data.itemData.type === "NON_MEDICAL" ? data.itemData.item_data.metadata.category : null}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Sub Category</p>
                            <p className='font-semibold'>{data.itemData.type === "NON_MEDICAL" ? data.itemData.item_data.metadata.sub_category : null}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Manufacturer</p>
                            <p className='font-semibold'>{data.itemData.type === "NON_MEDICAL" ? data.itemData.item_data.metadata.manufacturer : null}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Owner</p>
                            <p className='font-semibold'>{data.itemData.type === "NON_MEDICAL" ? data.itemData.item_data.metadata.owner : null}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Unit</p>
                            <p className='font-semibold'>{data.itemData.item_data.unit}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Unit Type</p>
                            <p className='font-semibold'>{data.itemData.item_data.unit_type}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Barcode</p>
                            <p className='font-semibold underline'>{data.itemData.item_data.barcode ? data.itemData.item_data.barcode : "N/A"}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>VAT %</p>
                            <p className='font-semibold'>{data.itemData.item_data.vat}%</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                </div>
            </div>
    )
}

export default Component