import React, { useState } from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useLiveQuery, usePGlite } from '@electric-sql/pglite-react';
import { toast } from '@/components/ui/use-toast';

interface QuantityModalProps {
  item: {
    id: number,
    sale_id: number,
    item_id: string,
    unit: number,
    unit_type: string,
    barcode: string,
    trade_name: string,
    quantity_added: string,
    item_discount: string,
    type: string
  };
  available_stock: number;
  selected_sale: number;
}

export function QuantityModal({ item, available_stock, selected_sale }: QuantityModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [tabState, setTabState] = useState('full');
  const pglite = usePGlite();
  
  const quantity_query = useLiveQuery<
    { quantity_added: number }
  >(`SELECT quantity_added FROM sales_items WHERE sale_id = ${selected_sale} AND item_id = '${item.item_id}'`, [item.item_id]);

  const handleQuantityChange = async (type: "ADD" | "REMOVE", partial: boolean = false) => {
    if (quantity_query?.rows[0].quantity_added! >= available_stock) {
      // Show an error message or toast
      toast({
        title: 'Error',
        description: 'Quantity should not exceed available stock',
        duration: 5000
      })
      return;
    }
    if (quantity_query?.rows[0].quantity_added! <= 0 && type === "REMOVE") {
      // Show an error message or toast
      toast({
        title: 'Error',
        description: 'Quantity should not be less than 1',
        duration: 5000
      })
      return;
    }
    try {
      await pglite.transaction(async (tx: any) => {
        if (!partial) {
          if (type === "ADD") {
            await tx.exec(`UPDATE sales_items SET quantity_added = quantity_added + 1 WHERE sale_id = ${selected_sale} AND item_id = '${item.item_id}'`);
          } else {
            await tx.exec(`UPDATE sales_items SET quantity_added = quantity_added - 1 WHERE sale_id = ${selected_sale} AND item_id = '${item.item_id}'`);
          }
        } else {
          const finalQuantity = 1/item.unit;
          if (type === "ADD") {
            await tx.exec(`UPDATE sales_items SET quantity_added = quantity_added + ${finalQuantity} WHERE sale_id = ${selected_sale} AND item_id = '${item.item_id}'`);
          } else {
            await tx.exec(`UPDATE sales_items SET quantity_added = quantity_added - ${finalQuantity} WHERE sale_id = ${selected_sale} AND item_id = '${item.item_id}'`);
          }
        }
      });
    } catch(e) {
      console.error(e);
    }
  };


  return (
    <>
      <div className="flex items-center gap-2">
        <Button 
          className='rounded-full'
          size={'icon'} 
          variant="outline"
          onClick={() => handleQuantityChange("REMOVE")}
        >
          <MinusIcon className="w-4 h-4" />
        </Button>
        <Input 
          className="w-12 text-center text-sm cursor-pointer" 
          value={quantity_query?.rows[0].quantity_added || 0}
          readOnly
          onClick={() => setIsOpen(true)}
        />
        <Button 
          className='rounded-full p-0'
          size="icon" 
          variant="outline"
          onClick={() => handleQuantityChange("ADD")}
        >
          <PlusIcon className="w-4 h-4" />
        </Button>
      </div>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Set Quantity for {item.trade_name}</DialogTitle>
          </DialogHeader>
          <hr className='my-2' />
          <Tabs className='grid place-items-center' defaultValue={tabState} onValueChange={(value) => setTabState(value)}>
            <TabsList>
              <TabsTrigger value="full">Full Quantity</TabsTrigger>
              <TabsTrigger value="partial">Partial Quantity</TabsTrigger>
            </TabsList>
            <hr className='my-2 w-full' />
            <TabsContent value="full">
              <div className="flex items-center justify-center space-x-4 my-8">
                <Button 
                    className='rounded-full'
                    size={'icon'} 
                    variant="outline"
                    onClick={() => handleQuantityChange("REMOVE")}
                >
                    <MinusIcon className="w-4 h-4" />
                </Button>
                <Input 
                  type="number" 
                  value={quantity_query?.rows[0].quantity_added || 0} 
                  readOnly
                  className="w-20 text-center"
                />
                <Button 
                    className='rounded-full p-0'
                    size="icon" 
                    variant="outline"
                    onClick={() => handleQuantityChange("ADD")}
                >
                    <PlusIcon className="w-4 h-4" />
                </Button>
              </div>
            </TabsContent>
            <TabsContent value="partial">
                <div className='my-8'>
                    <div className='mb-4 text-sm'>
                        <p>What is partial quantity?</p>
                        <p>What is minimum sellable unit?</p>
                    </div>
                    <p className='text-gray-500 text-sm'>Available Stock: <span className='text-black'>{available_stock}</span></p>
                    <p className='text-gray-500 text-sm'>Minimum Sellable Unit: <span className='text-black'>1 {item.unit_type}</span></p>
                    <div className="flex items-center justify-center space-x-4 my-8">
                        <Button 
                            className='rounded-full'
                            size={'icon'} 
                            variant="outline"
                            onClick={() => handleQuantityChange("REMOVE", true)}
                        >
                            <MinusIcon className="w-4 h-4" />
                        </Button>
                        <Input 
                        type="number" 
                        value={quantity_query?.rows[0].quantity_added! * item.unit || 0} 
                        className="w-20 text-center"
                        readOnly
                        />
                        <Button 
                            className='rounded-full p-0'
                            size="icon" 
                            variant="outline"
                            onClick={() => handleQuantityChange("ADD", true)}
                        >
                            <PlusIcon className="w-4 h-4" />
                        </Button>
                    </div>
                    <div className="mt-4">
                        <p className='text-gray-500 text-sm'>Calculations: <span className='text-black'>{quantity_query?.rows[0].quantity_added!} * {item.unit}</span> = <span className='text-black'>{quantity_query?.rows[0].quantity_added! * item.unit} {item.unit_type}</span></p>
                    </div>
                </div>
            </TabsContent>
          </Tabs>
          <DialogFooter>
            <Button onClick={() => setIsOpen(false)}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}

function MinusIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
    </svg>
  )
}

function PlusIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
      <path d="M12 5v14" />
    </svg>
  )
}