import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Building, Link } from "lucide-react";
import { Route as salesOneRoute } from "@/routes/overview/sales_history/$saleId";
import { useNavigate } from "@tanstack/react-router";

const DetailsPath: React.FC = () => {
  const data = salesOneRoute.useLoaderData();
  const navigate = useNavigate();
  const searchParams = salesOneRoute.useSearch();
  return (
    <div className="">
      <Breadcrumb>
        <BreadcrumbList className="">
          <BreadcrumbItem>
            <BreadcrumbLink
              onClick={() => {
                navigate({
                  to: "/overview",
                });
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Overview
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink
              onClick={() => {
                navigate({
                  to: "/overview/sales_history",
                  search: searchParams,
                });
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Sales History
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{data.invoice_id}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <hr className="my-4" />
    </div>
  );
};

export default DetailsPath;
