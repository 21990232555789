import React, { useCallback, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  CaretSortIcon,
} from "@radix-ui/react-icons";
import { CircleXIcon, MoveRightIcon } from "lucide-react";
import { Route as overviewRoute } from "@/routes/overview/sales_history/index";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import { useNavigate } from "@tanstack/react-router";
import { AutoComplete, Option } from "@/components/ui/autocomplete";
import { DatePickerWithRange } from "@/components/ui/date-picker";
import { DateRange } from "react-day-picker";

const Component = () => {
  const props = overviewRoute.useLoaderData();
  const searchParams = overviewRoute.useSearch();
  console.log(searchParams);
  const { page, pageSize, userID, startDate, endDate, type } = searchParams;
  const navigate = useNavigate({ from: "/overview/sales_history" });
  const { users } = props;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [userName, setUserName] = useState<string>("");
  const [searchValue, setSearchValue] = useState<Option | undefined>(undefined);
  console.log("Search Params:", searchParams);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from:
      startDate && startDate > 0 ? dayjs.unix(startDate).toDate() : undefined,
    to: endDate && endDate > 0 ? dayjs.unix(endDate).toDate() : undefined,
  });

  const handleDateChange = useCallback(
    (newDateRange: DateRange | undefined) => {
      setDateRange(newDateRange);
    },
    []
  );

  const handleSubmit = useCallback(() => {
    if (dateRange?.from && dateRange?.to) {
      navigate({
        search: {
          page: 1,
          pageSize: pageSize,
          userID: userID,
          startDate: Math.round(dateRange.from.getTime() / 1000),
          endDate: Math.round(dateRange.to.getTime() / 1000),
          type: type,
        },
      });
    }
  }, [navigate, dateRange, page, pageSize, userID, startDate, endDate, type]);

  const sortedData = props.sales_history.slice().sort((a, b) => {
    if (orderBy === "created_at") {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  const filteredDropDownContent = users.filter((user) => {
    return user.first_name?.trim() !== "" || user.last_name?.trim() !== "";
  });

  const options: Option[] = filteredDropDownContent.map((user) => ({
    value: user.id,
    label: `${user.first_name} ${user.last_name} (${user.username})`,
  }));

  useEffect(() => {
    const selectedUser = filteredDropDownContent.find((u) => u.id === userID);
    setUserName(
      selectedUser
        ? `${selectedUser.first_name} ${selectedUser.last_name} (${selectedUser.username})`
        : ""
    );
    setSearchValue(
      selectedUser
        ? {
            label: `${selectedUser.first_name} ${selectedUser.last_name} (${selectedUser.username})`,
            value: selectedUser.id,
          }
        : { label: "", value: "" }
    );
  }, [searchParams, filteredDropDownContent]);

  const resetSearch = () => {
    navigate({
      search: {
        page: 1,
        pageSize: pageSize,
        userID: "",
        type: "",
      },
    });
  };

  const clearDate = () => {
    setDateRange({ from: undefined, to: undefined });
    navigate({
      search: {
        ...searchParams,
        page: 1,
        startDate: undefined,
        endDate: undefined,
      },
    });
  };

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/overview">Overview</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Sales History</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="px-4 py-2 h-10 ml-20">
          <Tabs defaultValue={type}>
            <TabsList>
              {["", "CARD", "CASH", "SPLIT"].map((type) => (
                <TabsTrigger
                  key={type}
                  value={type}
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        type,
                        page: 1,
                      },
                    });
                  }}
                >
                  {type || "All"}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
        </div>
        <div className="flex items-center justify-center gap-x-4 h-8">
          <div className="flex items-center border border-input rounded-md bg-white">
            <DatePickerWithRange
              date={dateRange}
              setDate={handleDateChange}
              onSubmit={handleSubmit}
              onClear={clearDate}
              disabledAfter={dayjs().toDate()}
              className="border-none h-8 w-full"
            />
            {dateRange?.from && dateRange?.to && (
              <Button
                className="border-0 h-auto p-2"
                variant="outline"
                onClick={clearDate}
              >
                <CircleXIcon className="h-4 w-4" />
              </Button>
            )}
          </div>
          <div className="flex items-center w-72 border border-input rounded-md bg-white">
            <AutoComplete
              options={options}
              placeholder="Filter by sales user"
              emptyMessage="No user found"
              input={userName}
              onInputChanged={(value) => setUserName(value)}
              onValueChange={(value) => {
                navigate({
                  search: {
                    page: 1,
                    pageSize: pageSize,
                    type: "",
                    userID: value.value,
                  },
                });
              }}
              value={searchValue}
              className="flex-1 border-0 "
            />
            {userName && (
              <Button
                className="border-0 h-auto p-2"
                variant="outline"
                onClick={resetSearch}
              >
                <CircleXIcon className="h-4 w-4" />
              </Button>
            )}
          </div>
        </div>
      </div>
      <hr className="my-4" />
      <div className="overflow-y-scroll h-screen pb-48">
        <Table className="mt-4">
          <TableHeader>
            <TableRow>
              <TableHead>Invoice ID</TableHead>
              <TableHead
                onClick={() => handleRequestSort("created_at")}
                style={{ cursor: "pointer" }}
              >
                <div className="flex items-center">
                  <span>Created At</span>
                  {orderBy === "created_at" && (
                    <CaretSortIcon
                      className={`ml-2 h-4 w-4 ${order === "asc" ? "rotate-180" : ""}`}
                    />
                  )}
                </div>
              </TableHead>
              <TableHead>Patient Name</TableHead>
              <TableHead>Emirates ID</TableHead>
              <TableHead>Phone Number</TableHead>
              <TableHead>ERX Number</TableHead>
              <TableHead>Payment Type</TableHead>
              <TableHead>Billed Amount</TableHead>
              <TableHead>Refunded</TableHead>
              <TableHead>Void</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedData.map((sale, index) => (
              <TableRow key={index}>
                <TableCell>{sale.invoice_id}</TableCell>
                <TableCell>
                  {dayjs(sale.created_at).format("DD MMM, YYYY")}
                </TableCell>
                <TableCell>
                  {sale.is_valid_patient ? sale.patient_info.name : "OTC"}
                </TableCell>
                <TableCell>
                  {sale.is_valid_patient ? sale.patient_info.emirates_id : "OTC"}
                </TableCell>
                <TableCell>
                  {sale.is_valid_patient ? sale.patient_info.phone : "OTC"}
                </TableCell>
                <TableCell>
                  {sale.is_valid_insurance
                    ? sale.insurance_details.erx_number
                    : "Not Available"}
                </TableCell>
                <TableCell>
                  {convertToCurrencyRounded(
                    sale.sale_amounts.card +
                    sale.sale_amounts.cash +
                    sale.sale_amounts.insurance_amount
                  )}
                </TableCell>
                <TableCell>{sale.sale_status.refunded ? "Yes" : "No"}</TableCell>
                <TableCell>{sale.sale_status.void ? "Yes" : "No"}</TableCell>
                <TableCell>
                  <Button
                    variant="link"
                    onClick={() => {
                      navigate({
                        to: `/overview/sales_history/${sale.sale_id}`,
                        search: searchParams,
                      });
                    }}
                  >
                    <MoveRightIcon className="w-4 h-4 mr-2" />
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <hr className="my-0" />
      <div className="flex items-center justify-center w-full fixed bottom-4 bg-white pt-6">
        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
          Page {page} of{" "}
          {props.sales_history.length > 0
            ? Math.ceil(props.sales_history[0].full_count / pageSize)
            : 1}
        </div>
        <div className="flex space-x-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: 1,
                },
              });
            }}
            disabled={page === 1}
          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: page - 1,
                },
              });
            }}
            disabled={page === 1}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: page + 1,
                },
              });
            }}
            disabled={
              props.sales_history.length === 0 ||
              props.sales_history[0].full_count <= page * pageSize
            }
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: Math.ceil(props.sales_history[0].full_count / pageSize),
                },
              });
            }}
            disabled={
              props.sales_history.length === 0 ||
              props.sales_history[0].full_count <= page * pageSize
            }
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Component;
