import React, { useState } from "react";
import { Badge } from "@/components/ui/badge";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { Route as salesOneRoute } from "@/routes/overview/sales_history/$saleId";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import { CaretSortIcon } from "@radix-ui/react-icons"; // Ensure you have this icon

export default function Items({
  partialMedicalItemsSelected,
  partialNonMedicalItemsSelected,
  setPartialMedicalItemsSelected,
  setPartialNonMedicalItemsSelected,
}: {
  partialNonMedicalItemsSelected: string[];
  partialMedicalItemsSelected: string[];
  setPartialMedicalItemsSelected: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  setPartialNonMedicalItemsSelected: React.Dispatch<
    React.SetStateAction<string[]>
  >;
}) {
  const data = salesOneRoute.useLoaderData();
  const navigate = useNavigate();
  const searchParams = salesOneRoute.useSearch();

  // Sorting state
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("expiry_date");

  // Sort function
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Sorting data
  const sortedMedicalItems = data.medical_items.slice().sort((a, b) => {
    if (orderBy === "expiry_date") {
      const dateA = new Date(a.expiry_date).getTime();
      const dateB = new Date(b.expiry_date).getTime();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  const sortedNonMedicalItems = data.non_medical_items.slice().sort((a, b) => {
    if (orderBy === "expiry_date") {
      const dateA = new Date(a.expiry_date.time).getTime();
      const dateB = new Date(b.expiry_date.time).getTime();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  return (
    <Card className="w-full border-none shadow-none overflow-auto">
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow className="h-[40px] m-0">
              <TableHead className="sm:table-cell">
                <Checkbox
                  className="h-4 w-4 border border-gray-500 rounded-sm"
                  checked={
                    data.sale_status.void ||
                    (partialMedicalItemsSelected.length ===
                      data.medical_items.length &&
                      partialNonMedicalItemsSelected.length ===
                        data.non_medical_items.length)
                  }
                  disabled={data.sale_status.refunded || data.sale_status.void}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setPartialMedicalItemsSelected(
                        data.medical_items.map((i) => i.sale_row_id)
                      );
                      setPartialNonMedicalItemsSelected(
                        data.non_medical_items.map((i) => i.sale_row_id)
                      );
                    } else {
                      setPartialMedicalItemsSelected([]);
                      setPartialNonMedicalItemsSelected([]);
                    }
                  }}
                ></Checkbox>
              </TableHead>
              <TableHead className="sm:table-cell">Item Name</TableHead>
              <TableHead className="sm:table-cell">Barcode</TableHead>
              <TableHead className="sm:table-cell">Batch Number</TableHead>
              <TableHead
                className="sm:table-cell cursor-pointer"
                onClick={() => handleRequestSort("expiry_date")}
              >
                <div className="flex items-center">
                  <span>Expiry Date</span>
                  {orderBy === "expiry_date" && (
                    <CaretSortIcon
                      className={`ml-2 h-4 w-4 ${
                        order === "asc" ? "rotate-180" : ""
                      }`}
                    />
                  )}
                </div>
              </TableHead>
              <TableHead className="sm:table-cell">Quantity</TableHead>
              <TableHead className="sm:table-cell">Price</TableHead>
              <TableHead className="sm:table-cell">Co-Pay</TableHead>
              <TableHead className="sm:table-cell">Total</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedMedicalItems.map((item, index) => (
              <TableRow className="h-[49px]" key={index}>
                <TableCell>
                  <Checkbox
                    className="h-4 w-4 border border-gray-500 rounded-sm"
                    checked={
                      item.sale_metadata.refunded ||
                      partialMedicalItemsSelected.includes(item.sale_row_id)
                    }
                    disabled={item.sale_metadata.refunded}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setPartialMedicalItemsSelected([
                          ...partialMedicalItemsSelected,
                          item.sale_row_id,
                        ]);
                      } else {
                        setPartialMedicalItemsSelected(
                          partialMedicalItemsSelected.filter(
                            (i) => i !== item.sale_row_id
                          )
                        );
                      }
                    }}
                  ></Checkbox>
                </TableCell>
                <TableCell>
                  <div className="font-medium">{item.trade_name}</div>
                </TableCell>
                <TableCell className="hidden sm:table-cell font-medium">
                  {item.barcode ? item.barcode : "N/A"}
                </TableCell>
                <TableCell className="hidden sm:table-cell font-medium">
                  {item.batch_number}
                </TableCell>
                <TableCell className="hidden md:table-cell font-medium">
                  {dayjs(item.expiry_date).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell className="hidden sm:table-cell font-medium">
                  {item.sale_quantity}
                </TableCell>
                <TableCell className="hidden sm:table-cell font-medium">
                  {convertToCurrencyRounded(item.price)}
                </TableCell>
                <TableCell className="hidden sm:table-cell font-medium">
                  {item.sale_metadata.insurance_used ? "Yes" : "No"}
                </TableCell>
                <TableCell className="hidden sm:table-cell font-medium">
                  {convertToCurrencyRounded(item.price * item.sale_quantity)}
                </TableCell>
              </TableRow>
            ))}
            {sortedNonMedicalItems.map((item, index) => (
              <TableRow className="h-[49px]" key={index}>
                <TableCell>
                  <Checkbox
                    className="h-4 w-4 border border-gray-500 rounded-sm"
                    checked={
                      item.sale_metadata.refunded ||
                      partialNonMedicalItemsSelected.includes(item.sale_row_id)
                    }
                    disabled={item.sale_metadata.refunded}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setPartialNonMedicalItemsSelected([
                          ...partialNonMedicalItemsSelected,
                          item.sale_row_id,
                        ]);
                      } else {
                        setPartialNonMedicalItemsSelected(
                          partialNonMedicalItemsSelected.filter(
                            (i) => i !== item.sale_row_id
                          )
                        );
                      }
                    }}
                  ></Checkbox>
                </TableCell>
                <TableCell>
                  <div className="font-medium">{item.trade_name}</div>
                </TableCell>
                <TableCell className="hidden sm:table-cell font-medium">
                  {item.barcode ? item.barcode : "N/A"}
                </TableCell>
                <TableCell className="hidden sm:table-cell font-medium">
                  {item.batch_number}
                </TableCell>
                <TableCell className="hidden md:table-cell font-medium">
                  {item.expiry_date.valid
                    ? dayjs(item.expiry_date.time).format("DD/MM/YYYY")
                    : "N/A"}
                </TableCell>
                <TableCell className="hidden sm:table-cell font-medium">
                  {item.sale_quantity}
                </TableCell>
                <TableCell className="hidden sm:table-cell font-medium">
                  {convertToCurrencyRounded(item.price)}
                </TableCell>
                <TableCell className="hidden sm:table-cell font-medium">
                  {item.sale_metadata.insurance_used ? "Yes" : "No"}
                </TableCell>
                <TableCell className="hidden sm:table-cell font-medium">
                  {convertToCurrencyRounded(item.price * item.sale_quantity)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
