import { Button } from '@/components/ui/button'
import BarChart from './components/chart/bar-chart'
import LineChart from './components/chart/line-chart'
import StatsBarChart from './components/chart/stats-bar-chart'
import { Route as accountsOverviewRoute } from '@/routes/accounts/index'
import { convertToCurrencyRounded } from '@/utils/currency_converter'
import { useNavigate } from '@tanstack/react-router'
import dayjs from 'dayjs'

const Component = () => {
    const { current_week_revenue, last_week_revenue } = accountsOverviewRoute.useLoaderData();
    const navigate = useNavigate();
    return (
        <>
            <div className="overflow-y-scroll h-screen pb-48">
                <div className="flex items-center justify-between">
                    <div>
                        <h2 className="text-2xl font-bold tracking-tight">Accounts Overview</h2>
                        <p className="text-muted-foreground">
                            Click on any of the cards to get more detailed view with more filters.
                        </p>
                    </div>
                    <Button
                        variant={"secondary"}
                    >
                        Customize
                    </Button>
                </div>
                <hr className="my-4" />
                <h2 className='text-3xl font-semibold mt-8 mb-4'>Revenue</h2>
                <div className='flex flex-col flex-1 gap-3'>
                    <LineChart
                        title="Revenue (Weekly)"
                        value={
                            convertToCurrencyRounded(current_week_revenue.reduce((acc, item) => acc + item.total_revenue, 0))
                        }
                        trend={
                            convertToCurrencyRounded(
                                last_week_revenue.reduce((acc, item) => acc + item.total_revenue, 0)
                            ) + " vs Last Week"
                        }
                        data={current_week_revenue}
                        xAxisKey="time_interval"
                        dataKey="total_revenue"
                        color="#71717A"
                        footer={
                            "Showing total revenue for the last 7 days"
                        }
                        xAxisType='date'
                        yAxisType='amount'
                        onClick={() => {
                            navigate({
                                to: '/accounts/revenue',
                            })
                        }}
                        active={true}
                    />
                    <StatsBarChart
                        title="Revenue (Weekly)"
                        value={convertToCurrencyRounded(current_week_revenue.reduce((acc, item) => acc + item.total_revenue, 0))}
                        trend={convertToCurrencyRounded(last_week_revenue.reduce((acc, item) => acc + item.total_revenue, 0)) + " vs Last Week"}
                        trendDirection='up'
                        data={current_week_revenue}
                        xAxisKey="time_interval"
                        dataKey="total_revenue"
                        color="#71717A"
                        footer="Showing total revenue for the last 7 days"
                        onClick={() => {
                            navigate({
                                to: '/accounts/revenue',
                            })
                        }}
                        active={true}
                    />
                </div>

                <hr className="my-4" />

                <div className='grid grid-cols-3 gap-x-6'>
                    <BarChart
                        title="COGS"
                        value="1.355 M"
                        trend="Trending up by 5.2% this month"
                        data={[
                            { month: "May", desktop: 186 },
                            { month: "June", desktop: 305 },
                            { month: "July", desktop: 186 },
                            { month: "August", desktop: 305 },
                            { month: "September", desktop: 186 },
                            { month: "October", desktop: 305 },
                        ]}
                        xAxisKey="month"
                        dataKey="desktop"
                        color="#1458DD"
                        footer="Showing totals for the last 6 months"
                        active={false}
                    />
                    <LineChart
                        title="Gross Margin"
                        value="9.7%"
                        trend="23.7 vs Last Month"
                        data={[
                            { month: dayjs().add(-5, 'd').format("DD"), desktop: 186 },
                            { month: dayjs().add(-4, 'd').format("DD"), desktop: 305 },
                            { month: dayjs().add(-3, 'd').format("DD"), desktop: 186 },
                            { month: dayjs().add(-2, 'd').format("DD"), desktop: 305 },
                            { month: dayjs().add(-1, 'd').format("DD"), desktop: 186 },
                            { month: dayjs().format("DD"), desktop: 305 },
                        ]}
                        xAxisKey="month"
                        dataKey="desktop"
                        color="#1458DD"
                        active={false}
                        footer='Showing totals for the last 6 days'
                    />
                </div>
            </div>
        </>
    )
}

export default Component